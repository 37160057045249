import React, {useEffect, useState, useRef, useMemo} from "react"
import { capitalizeFirstLetter } from "../../utility/Utilities"
import classNames from "classnames"
import { IoMdArrowDropright } from "react-icons/io";
import { PiWarning } from "react-icons/pi";
import "./ClubConfig.css"

function ClubConfigColorBooleanSelection ({name, colors, edits, updateEdits, editMode}) {

    const getValue = () => {
        const override = edits.find((e) => e.name === name)
        if (override) return override.value
        else return colors[name]
    }

    const value = getValue()
    const isOriginal = value === colors[name]
    
    const onChangeColor = (name, value, reset=false) => {
        updateEdits({type: "color", name: name, value:value, reset: reset})
    }

    return (
        <div className="club-config-data">
            <div className="club-config-data-title">Dark mode</div>
            {editMode ? (
                <div className="club-config-data-value">
                    <div className="color-value-toggle">
                        <div onClick={() => onChangeColor(name, !value)} className={classNames("toggle-switch", {"active": !value})}>
                            <div className="toggle-button"></div>
                        </div>
                        {!isOriginal && (
                        <div className="set-to-original">
                            {/* <div className="club-config-dot"></div> */}
                            <div onClick={() => onChangeColor(name, colors[name], true)} className="config-reset-btn">
                                Reset
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            ) : (
                <div className="club-config-data-value">{value ? "No" : "Yes"}</div>
            )}
        </div>
    )
}

function ClubConfigColorSelection ({name, colors, edits, updateEdits, editMode, advancedColors=false}) {

    const color = colors[name]
    
    const [newColor, setNewColor] = useState(color)
    const [resetRenderKey, setResetRenderKey] = useState(0)

    const isOriginal = useMemo(() => newColor === colors[name], [newColor])

    const overriddenAdvancedColorValue = () => {
        if (!advancedColors) return null
        const override = edits.find((e) => e.name === name)
        return override?.value || null
    }
    const overriddenAdvancedColor = overriddenAdvancedColorValue()
    
    const hasColorsEdit = edits.filter((e) => e.type === "color" || e.type === "css").filter((e) => !e.reset).length !== 0

    useEffect(() => {
        if (advancedColors && overriddenAdvancedColor) return
        setNewColor(color)
    }, [color])

    useEffect(() => {
        if (!editMode) setNewColor(color)
    }, [editMode])

    // If there's no more actively edited color, but the color is not original yet, set to original
    // Will be triggered when resetting all colors (clear any color and css edits) 
    useEffect(() => {
        if (!hasColorsEdit && !isOriginal) {
            const setToOriginal = setTimeout(() => {
                setNewColor(color)
            }, advancedColors ? 200 : undefined)
            return () => clearTimeout(setToOriginal)
        }
    }, [hasColorsEdit, isOriginal])

    // Typing color from text input
    const onTypeColor = (color) => {
        setNewColor(color)
        const type = advancedColors ? "css" : "color"
        const inputIsHex = /^#[A-Fa-f0-9]{6}$/.test(color)
        if (inputIsHex) updateEdits({type: type, name: name, value: color})
        if (color === "") updateEdits({type: type, name: name, value: undefined})
    }
    
    const onChangeColor = (name, value=undefined, reset=false) => {
        setNewColor(value)
        if (value === "") value = undefined
        updateEdits({type: advancedColors ? "css" : "color", name: name, value: value, reset: reset})
        if (reset) setResetRenderKey(prev => prev + 1)
    }

    const lightMode = colors.lightMode
    const backgroundFollowsLightMode = name === "background" && !color

    const colorNameTitle = name.replace(/--/g, "").replace(/-/g, " ")
    
    const checkShowRemove = () => {
        const removeAbleColor = ["header", "background"]
        if (!removeAbleColor.includes(name)) return false
        if (colors[name] && !!newColor) return true
    }
    const showRemove = checkShowRemove()

    const getLightModeValue = () => {
        const override = edits.find((e) => e.name === "lightMode")
        if (override) return override.value
        else return lightMode
    }
    // TODO better naming, being used in checking undefined background color
    const isLightModeValue = getLightModeValue()

    const isColorSchemeInAdvanced = name === "--color-scheme"
    const isBgColorInAdvanced = name === "--bg-color"
    if (isColorSchemeInAdvanced || isBgColorInAdvanced) return null

    // Variable for undefined color (header and background)
    let noValueColor
    if (name === "header") noValueColor = "#000000"
    if (name === "background" && !newColor) noValueColor = isLightModeValue ? "#FFFFFF" : "#000000"

    return (
        <div className="club-config-data">
            <div className="club-config-data-title">
                {capitalizeFirstLetter(colorNameTitle)}
            </div>
            <div className="club-config-data-value color">
                {editMode ? (
                    <div key={resetRenderKey} className="data-color">
                        <input 
                            type="color"
                            disabled={!editMode}
                            value={newColor || noValueColor}
                            onChange={(e) => onChangeColor(name, e.target.value)}
                            className={classNames("data-color-box", {
                                "active": editMode,
                            })}
                            />
                        <input 
                            type="text" 
                            value={newColor || ""}
                            onChange={(e) => onTypeColor(e.target.value)}
                            className="new-color-input"/>
                        {!isOriginal && (
                            <div onClick={() => onChangeColor(name, colors[name], true)} className="config-reset-btn">
                                Reset
                            </div>
                        )}
                        {showRemove && (
                            <div onClick={() => onChangeColor(name, "")} className="config-reset-btn">
                                Remove
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        {backgroundFollowsLightMode ? (
                            <div className="background-light-mode-info">
                                Dark mode {lightMode ? "inactive" : "active"}: Background is {lightMode ? "white" : "black"}
                            </div>
                        ) : (
                            <div className="data-color">
                                {color && (
                                    <div style={{backgroundColor: color}} className="config-color-box"></div>
                                )}
                                {color || "None"}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

function ClubConfigColors ({colors, css, originalCss, setCssVariables, edits, setEdits, updateEdits, editMode, configSection, contrastSummary, previewWindowIsOpen, updatePreviewConfig}) {
    
    const [openAdvancedColors, setOpenAdvancedColors] = useState(false)

    // const { ref: bottomListRef, inView: bottomListInView } = useInView({initialInView: false});
    const colorsSectionRef = useRef()

    useEffect(() => {
        if (!editMode && openAdvancedColors) setOpenAdvancedColors(false)
    }, [editMode])

    useEffect(() => {
        if (configSection === "colors") colorsSectionRef.current?.scrollIntoView({behavior: "smooth"})
    }, [configSection])

    if (!colors || !css) return null

    const resetAllColors = () => {
        const originalColorsObject = {
            colors: colors,
            css: originalCss,
        }
        const editsWithoutColors = edits.filter((e) => e.type !== "color").filter((e) => e.type !== "css")
        setCssVariables(originalCss)
        setEdits(editsWithoutColors)
        if (previewWindowIsOpen) updatePreviewConfig(originalColorsObject)
    }
    
    const colorsNames = ["primary", "accent", "contrast", "header", "background"]
    
    const colorsOverrides = (
        <div className={classNames("colors-overrides-cont", {"show": openAdvancedColors})}>
            <div className="colors-overrides-disclaimer">
                We attempt to create the best colorscheme possible based on the provided colors, 
                assigning colors to many of the page elements, but not all combinations will work perfectly. 
                Sometimes a color is not used, or we might change which of the provided colors we use where, 
                to avoid conflicts or poor contrast. 
                <br />
                If necessary, you may attempt to override individual 
                values if you believe the contrast is sufficient. Be advised that in some places this could 
                cause visual issues . For example, white text being illegible on a yellow background.
            </div>
            <div className="colors-overrides-title">
                Color overrides
                <div onClick={() => setOpenAdvancedColors(!openAdvancedColors)} className="club-config-dropdown-toggle">
                    {openAdvancedColors ? "Hide" : "Show"} <IoMdArrowDropright />
                </div>
            </div>
            <div className="colors-overrides-list">
                {Object.entries(css).map(([key,]) => {
                    return (
                        <ClubConfigColorSelection 
                            key={key} 
                            name={key} 
                            colors={css}
                            edits={edits}
                            updateEdits={updateEdits}
                            editMode={editMode}
                            advancedColors/>
                    )
                })}
            </div>
        </div>  
    )

    const contrastSummaryInfo = (editMode && contrastSummary.length !==0) && (
        <div className="contrast-warning-cont">
            <div className="contrast-warning-title">
                <PiWarning/>
                Insufficient contrast warning
            </div>
            <div className="contrast-warning-list">
                {contrastSummary.map((c) => {
                    return (
                        <div key={c}>- {c}</div>
                    )
                })}
            </div>
        </div>
    )

    const hasColorsEdit = edits.filter((e) => e.type === "color" || e.type === "css").filter((e) => !e.reset).length !== 0

    return (
        <div ref={colorsSectionRef} className="club-config-section">
            <div className="club-config-section-title space-between">
                Colors
                {hasColorsEdit && (
                    <div onClick={resetAllColors} className="config-reset-btn">Reset all colors</div>
                )}
            </div>
            {colorsNames.map((n) => {
                return (
                    <ClubConfigColorSelection 
                        key={n}
                        name={n}
                        colors={colors}
                        edits={edits}
                        updateEdits={updateEdits}
                        editMode={editMode}/>
                )
            })}
            <ClubConfigColorBooleanSelection 
                name={"lightMode"}
                colors={colors}
                edits={edits}
                updateEdits={updateEdits}
                editMode={editMode}
                />
            {contrastSummaryInfo}
            {colorsOverrides}
        </div>
    )
}

export default ClubConfigColors