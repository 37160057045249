import React, {useEffect, useState} from "react"
import VideoSearchEntry from "./VideoSearchEntry"
import {useSWRConfig} from "swr";
import {useNavigate} from "react-router-dom";
import { Paging } from "../utility/Paging";
import { useAddVideoToAction } from "../utility/Utilities";
import { useLoginSession } from "../stores/loginSession";
import LoadingScreen from "./LoadingScreen";
import VideoAction from "./VideoAction";
import { forzifyAnalytics } from "../utility/analytics";
import { useBackend } from "../utility/Backend";
import classNames from "classnames";
import "../components/css/VideoSearchEntry.css"

const useFetchSearchResultWithTiming = (url, query) => {

    const [fetchDuration, setFetchDuration] = useState(null)

    const startTime = performance.now()

    const {data, error} = useBackend(url, query, {
        revalidateOnFocus: false,
        onSuccess: () => {
            const endTime = performance.now()
            const duration = endTime - startTime
            setFetchDuration(duration.toFixed(0))
        },
    });
    return {data, error, fetchDuration}
};

function SearchResultSingle ({idx, playlist, selectedVideos, addVideoToList, removeVideoFromList}) {

    const {game, date} = playlist

    const {cache} = useSWRConfig();
    const navigate = useNavigate();

    const urlPathname = window.location.pathname
    const urlAfterLibrary = urlPathname.substring(urlPathname.indexOf("y") + 1);

    function onClick () {
        cache.set("editing_playlist", playlist);
        // Doing this a little more manually to avoid using a hook in dozens/hundreds of small components
        const q = new URLSearchParams(window.location.search);
        q.set("editing", "playlist_" + playlist.id);
        navigate("/library" + urlAfterLibrary + "?" + q.toString());
        return false;
    }

    return (
        <VideoSearchEntry
            tabIndex={20+idx} 
            onClick={onClick}
            playlist={playlist}
            game={game}
            timestamp={date}
            active={false}
            disableAction={false}
            selectedVideos={selectedVideos}
            addVideoToList={addVideoToList}
            removeVideoFromList={removeVideoFromList}
            />
    )
}

function FreeTextSearchResults ({searchProp, searchInput, inputParams, from, to, showingSuggestionsOrInfo=false}) {
    
    const [
        selectedVideos, ,
        addVideoToList, 
        removeVideoFromList, 
        clearSelectedList, ,
    ] = useAddVideoToAction();

    const {user} = useLoginSession()

    const [searchResultsPage, setSearchResultsPage] = useState(1)

    useEffect(async () => {
        setSearchResultsPage(1)
    }, [inputParams])

    const resultsPerPage = 10
    const fromPage = searchResultsPage === 1 ? 0 : (searchResultsPage-1) * resultsPerPage

    const query = {
        count: resultsPerPage,
        from: fromPage,
    }

    if (from && to) {
        query["from_date"] = new Date(from).toISOString()
        query["to_date"] = new Date(to).toISOString()
    }

    const {data, error, fetchDuration} = useFetchSearchResultWithTiming("/freetext" + inputParams, query)
    
    const analyzeSearch = () => {

        const action = searchProp.find((p) => p.tag === "action")
        const actionName = action?.name

        const paramTags = searchProp.map(p => p.tag)
        paramTags.sort((a,b) => a.localeCompare(b))

        const paramTagsString = paramTags.join(", ")

        forzifyAnalytics("search", {
            duration: fetchDuration,
            url: inputParams,
            total: data.total,
            text: searchInput,
            action: actionName,
            objects: paramTagsString,
            app_name: "mam",
            user: user.id,
        })
    }

    useEffect(() => {
        if (data && fetchDuration) analyzeSearch()
    }, [data])

    if (error) return (
        <div className="no-results-found">
            Nothing found
        </div>
    )

    if (!data) return (
        <div className="loading-search-results">
            <LoadingScreen/>
        </div>
    )

    const playlist = data?.playlists || []
    const totalPage = Math.ceil(data?.total / resultsPerPage)

    const onSearchResultsPageChange = (page) => {
        setSearchResultsPage(page)
    }

    if (playlist.length === 0) return (
        <div className="no-results-found">
            Nothing found
        </div>
    )

    return (
        <div className={classNames("search-results-cont", {"filter": showingSuggestionsOrInfo})}>
            <div className="search-results-number">{data.total} Results found</div>
            <VideoAction 
                selectedVideos={selectedVideos}
                clearSelectedList={clearSelectedList}/>
            <ul className="search-results-list">
                {playlist.map((playlist, idx) => {
                    return (
                        <SearchResultSingle 
                            key={playlist.id}
                            idx={idx}
                            playlist={playlist}
                            selectedVideos={selectedVideos}
                            addVideoToList={addVideoToList}
                            removeVideoFromList={removeVideoFromList}/>
                    )
                })}
            </ul>
            <Paging page={searchResultsPage} pageCount={totalPage} onChange={onSearchResultsPageChange}/>
        </div>
    )
}

export default FreeTextSearchResults