import React, {useState, useEffect} from "react"
import { useBackend } from "../../utility/Backend";
import PercentageProgress from "../../components/PercentageProgress";
import { useMutateByRegex } from "../../utility/Utilities";

function UploadMessage ({assetId}) {

    const [uploadIsFailed, setUploadIsFailed] = useState(false)

    const {data: uploadStatusData} = useBackend("/asset/" + assetId + "/status", {}, {
        dedupingInterval: uploadIsFailed ? undefined : 2900,
        refreshInterval: uploadIsFailed ? undefined : 3000,
    })

    const uploadProgress = uploadStatusData?.progress
    const uploadStatus = uploadStatusData?.status
    const uploadFailed = uploadStatus?.includes("Failure")

    useEffect(() => {
        if (uploadFailed) setUploadIsFailed(true)
    }, [uploadFailed])

    const mutateByRegex = useMutateByRegex()

    useEffect(() => {
        if (uploadProgress === 100 || uploadStatus === "success") mutateByRegex(/^\/playlist\//)
    }, [uploadProgress, uploadStatus])
    
    if (!uploadStatusData) return null

    if (uploadStatus === "success") return null

    if (uploadFailed) return (
        <div className="upload-message-failed">
            <div className="upload-message-title">Upload status :</div>
            <div className="upload-message-status">{uploadStatus}</div>
        </div>
    )

    return (
        <div className="upload-message">
            <PercentageProgress percentage={uploadProgress} title={uploadStatus} type="upload"/>
        </div>
    )
}

export default UploadMessage