import React, {useEffect, useState, useRef} from "react"
import UploadImage from "../../components/UploadImage"
import classNames from "classnames"
import "./ClubConfig.css"

function ClubConfigHero ({heroImage, edits, updateEdits, editMode, updateHeroImage, configSection}) {

    const getValue = (name) => {
        const override = edits.find((e) => e.name === name)
        if (override) return override.value
        else return heroImage[name]
    }
    
    const url = getValue("url")
    const position = getValue("position")
    const heroLogo = getValue("logo_url")
    const heroMessage = getValue("message")

    const startingPageSectionRef = useRef()
    
    const convertPositionValue = (position) => {
        const percentageRegex = /^(100|[1-9]?[0-9])%$/;
        const isPercentage = percentageRegex.test(position);

        if (!position || position === undefined) return "50"
        else if (position === "center") return "50"
        else if (position === "top") return "0"
        else if (isPercentage) return position.replace("%", "")
    }

    const oriPositionParts = heroImage.position.split(" ");
    const [oriHorizontal, oriVertical] = oriPositionParts.length > 1 ? [convertPositionValue(oriPositionParts[0]), convertPositionValue(oriPositionParts[1])] : ["50", convertPositionValue(oriPositionParts[0])]
    const positionParts = position.split(" ");
    const [horizontal, vertical] = positionParts.length > 1 ? [convertPositionValue(positionParts[0]), convertPositionValue(positionParts[1])] : ["50", convertPositionValue(positionParts[0])]
    
    const [horizontalValue, setHorizontalValue] = useState(horizontal)
    const [verticalValue, setVerticalValue] = useState(vertical)
    const [imageObjectPosition, setImageObjectPosition] = useState(position)
    const [heroImageSize, setHeroImageSize] = useState("desktop")
    const [newHeroMessage, setNewHeroMessage] = useState("")
    const [editingHero, setEditingHero] = useState(null)

    useEffect(() => {
        if (!editMode) {
            setHorizontalValue(horizontal)
            setVerticalValue(vertical)
            setImageObjectPosition(position)
            setHeroImageSize("desktop")
            setNewHeroMessage(heroMessage)
            setEditingHero(null)
        }
    }, [editMode])

    useEffect(() => {
        if (heroImage.message) setNewHeroMessage(heroImage.message)
    }, [heroImage])

    useEffect(() => {
        updateEdits({type: "heroImage", name: "message", value: newHeroMessage})
    }, [newHeroMessage])

    useEffect(() => {
        setImageObjectPosition (`${horizontalValue}% ${verticalValue}%`)
    }, [horizontalValue, verticalValue])

    useEffect(() => {
        if (configSection === "starting page") startingPageSectionRef.current?.scrollIntoView({behavior: "smooth"})
    }, [configSection])

    if (!heroImage) return null

    const onChangeImage = (imgAndUrl) => {
        updateHeroImage(imgAndUrl.imageFile, editingHero)
        updateEdits({type: "heroImage", name: "message", value: newHeroMessage})
    }

    const originalUrl = url === heroImage.url
    const originalHorizontal = horizontalValue === oriHorizontal
    const originalVertical = verticalValue === oriVertical
    const originalHeroLogo = heroLogo === heroImage.logo_url
    const originalHeroMessage = heroImage.message ? newHeroMessage === heroImage.message : !newHeroMessage

    const onChangeHero = (name, value, reset=false) => {
        updateEdits({type: "heroImage", name: name, value: value, reset: reset})
    }

    const onChangePosition = (name, direction, value, reset=false) => {
        if (direction === "horizontal") {
            setHorizontalValue(value)
            
        } 
        if (direction === "vertical") {
            setVerticalValue(value)
            setHeroImageSize("desktop")
        }
        onChangeHero(name, `${horizontalValue}% ${verticalValue}%`, reset)
    }

    const onResetHeroMessage = () => {
        if (heroImage.message) setNewHeroMessage(heroImage.message)
        else setNewHeroMessage("")
        updateEdits({type: "heroImage", name: "message", value: heroImage.message, reset: true})
    }

    const homeHeroImage = (
        <div className="club-config-hero">
            <div className="hero-image-size-cont">
                <div 
                    onClick={() => setHeroImageSize("mobile")} 
                    className={classNames("hero-image-size", {"active": heroImageSize === "mobile"})}>
                    Mobile
                </div>
                <div 
                    onClick={() => setHeroImageSize("desktop")} 
                    className={classNames("hero-image-size", {"active": heroImageSize === "desktop"})}>
                    Desktop
                </div>
                <div 
                    onClick={() => setHeroImageSize("full")} 
                    className={classNames("hero-image-size", {"active": heroImageSize === "full"})}>
                    Full size
                </div>
            </div>
            <div className={classNames("club-config-hero-img", {
                "mobile": heroImageSize === "mobile",
                "desktop": heroImageSize === "desktop",
            })}>
                <img src={url} alt="hero" style={{objectPosition: imageObjectPosition}}/>
            </div>
            <div className={classNames("club-config-hero-change", {"active": editMode})}>
                <div onClick={() => setEditingHero("hero")} className="club-config-edit">Change</div>
                {!originalUrl && (
                    <div onClick={() => onChangeHero("url", heroImage.url, true)} className="config-reset-btn">
                        Reset
                    </div>
                )}
            </div>
        </div>
    )
    
    const heroPosition = (
        <div className="hero-position-cont">
            <div className="hero-position-part">
                <div className="hero-position-input-cont">
                    <input 
                        type="range" 
                        min="0" 
                        max="100" 
                        step="10" 
                        disabled={!editMode}
                        value={horizontalValue} 
                        onChange={(e) => setHorizontalValue(e.target.value)}
                        onMouseUp={() => onChangePosition("position", "horizontal", horizontalValue)} 
                        onMouseDown={() => setHeroImageSize("mobile")}
                        className={classNames("hero-position-input", {"disabled": !editMode})}
                        />
                    <div className="hero-position-indicator start">Left</div>
                    <div className="hero-position-indicator mid">Center</div>
                    <div className="hero-position-indicator end">Right</div>
                </div>
                {!originalHorizontal && (
                    <div onClick={() => onChangePosition("position", "horizontal", oriHorizontal, true)} className="config-reset-btn">
                        Reset
                    </div>
                )}
            </div>
            <div className="hero-position-part">
                <div className="hero-position-input-cont">
                    <input 
                        type="range" 
                        min="0" 
                        max="100" 
                        step="10" 
                        disabled={!editMode}
                        value={verticalValue} 
                        onChange={(e) => setVerticalValue(e.target.value)}
                        onMouseUp={() => onChangePosition("position", "vertical", verticalValue)} 
                        onMouseDown={() => setHeroImageSize("desktop")}
                        className={classNames("hero-position-input vertical", {"disabled": !editMode})}
                        />
                    <div className="hero-position-indicator start">Bottom</div>
                    <div className="hero-position-indicator mid">center</div>
                    <div className="hero-position-indicator end">Top</div>
                </div>
                {!originalVertical && (
                    <div onClick={() => onChangePosition("position", "vertical", oriVertical, true)} className="config-reset-btn">
                        Reset
                    </div>
                )}
            </div>
        </div>
    )

    const homeHeroLogo = (
        <div className="club-config-hero-logo">
            {heroLogo ? (
                <img src={heroLogo} alt="hero logo" className="club-config-hero-logo-img"/>
            ) : (
                <div className="grey-color-text">
                    None (Default)
                </div>
            )}
            <div className={classNames("club-config-hero-change", {"active": editMode})}>
                <div onClick={() => setEditingHero("hero_logo")} className="club-config-edit">
                    {heroLogo ? "Change" : "Add"}
                </div>
                {!originalHeroLogo && (
                    <div onClick={() => onChangeHero("logo_url", heroImage.logo_url, true)} className="config-reset-btn">
                        Reset
                    </div>
                )}
                {(heroImage.logo_url && heroLogo) && (
                    <div onClick={() => onChangeHero("logo_url", null)} className="config-reset-btn">
                        Remove
                    </div>
                )}
            </div>
        </div>
    )

    const homeHeroMessage = editMode ? (
        <div className="club-config-message">
            <input 
                type="text"
                value={newHeroMessage}
                onChange={(e) => setNewHeroMessage(e.target.value)} 
                className="hero-message-input"
                />
            {!originalHeroMessage && (
                <div onClick={onResetHeroMessage} className="config-reset-btn">
                    Reset
                </div>
            )}
        </div>
    ) : (
        <div className={classNames("club-config-message", {"grey-color-text": !heroMessage})}>
            {heroMessage || "None (Default)"}
        </div>
    )
    
    return (
        <div ref={startingPageSectionRef} className="club-config-section">
            <div className="club-config-section-title">Starting page</div>
            <div className="club-config-data align-start">
                <div className="club-config-data-title">Hero image</div>
                <div className="club-config-data-value">
                    {homeHeroImage}
                </div>
            </div>
            <div className="club-config-data align-start">
                <div className="club-config-data-title">Position</div>
                <div className="club-config-data-value">
                    {heroPosition}
                </div>
            </div>
            <div className="club-config-data align-start">
                <div className="club-config-data-title">Hero logo</div>
                <div className="club-config-data-value">
                    {homeHeroLogo}
                </div>
            </div>
            <div className="club-config-data">
                <div className="club-config-data-title">Hero message</div>
                <div className="club-config-data-value">
                    {homeHeroMessage}
                </div>
            </div>
            {editingHero && (
                <UploadImage 
                    onClose={() => setEditingHero(null)}
                    onChange={onChangeImage}/>
            )}
        </div>
    )
}

export default ClubConfigHero