import React, { useState, useRef } from "react"
import { useBackend } from "../../utility/Backend";
import classNames from "classnames"
import { ClickOutside } from "../../utility/Utilities";
import {BsInfoCircle} from "react-icons/bs";
import "./Payment.css"
import { getDateFormat } from "../../utility/Utilities";
import {TiArrowSortedDown} from "react-icons/ti";

function PricePlansDropdown ({merchantId, price, setPrice, disabled=false}) {

    const [showPrices, setShowPrices] = useState(false)

    const dropdownListRef = useRef(null)
    ClickOutside(dropdownListRef, setShowPrices)

    const {data: pricesData} = useBackend("/cee_merchant/" + merchantId + "/price_plan", {});
    
    if (!pricesData) {
        return (
            <div className="select-merchant-for-price">
                Getting prices...
            </div>
        )
    }
    
    const prices = pricesData?.price_plans || []

    const handleShowPrices = () => {
        if (disabled) return null
        else setShowPrices(!showPrices)
    }

    const handleSelectPrice = (price) => {
        setPrice(price)
        setShowPrices(false)
    }

    if (prices?.length === 0) {
        return (
            <div className="select-merchant-for-price">
                No available price
            </div>
        )
    }

    return (
        <div ref={dropdownListRef} className="upload-dropdown-cont">
            <div onClick={handleShowPrices} className="upload-dropdown-title">
                {price? price.name : "Select price"}
                <TiArrowSortedDown/>
            </div>
            {showPrices && (
                <div className="upload-dropdown-list">
                    <ul className="payment-option-list">
                        {prices.map((p) => {
                            return (
                                <li
                                    key={p.name}
                                    onClick={() => handleSelectPrice(p)}
                                    className={classNames("payment-option-single", {"active": p.price_amount === price?.price_amount})}
                                    >
                                    {p.name}
                                </li>
                            )
                        })}
                    </ul> 
                </div>        
            )}
        </div>
    )
}

function PayPerView ({ 
    paymentPeriod,
    setPaymentPeriod, 
    price,
    setPrice,
    subscriptionSelected,
    merchant,
    startTime,
    disabled, 
}) {
    
    const [showPeriods, setShowPeriods] = useState(false)

    const periodListRef = useRef(null)
    ClickOutside(periodListRef, setShowPeriods)

    const handleShowPeriods = () => {
        if (disabled) return null
        if (subscriptionSelected) return null
        else setShowPeriods(!showPeriods)
    }

    const handleSelectPeriod = (period) => {
        setPaymentPeriod(period)
        setShowPeriods(false)
    }

    let startDate = new Date(startTime)
    startDate.setDate(startDate.getDate() + paymentPeriod)
    const periodsList = [3, 7, 30]

    return (
        <div className="payment-ppv">
            <div className="input-container payment-dropdown">
                <label className="input-title">Price</label>
                {merchant? (
                    <PricePlansDropdown 
                        merchantId={merchant.id} 
                        price={price} 
                        setPrice={setPrice}
                        disabled={disabled}/>
                ) : (
                    <div className="select-merchant-for-price">
                        Select merchant to show prices
                    </div>
                )}
            </div>
            <div ref={periodListRef} className={classNames("input-container payment-dropdown", {
                "disabled": subscriptionSelected,
            })}>
                <label className="input-title">Payment required for</label>
                <div onClick={handleShowPeriods} className="upload-dropdown-cont">
                    <div className="upload-dropdown-title">
                        {paymentPeriod? `${paymentPeriod} days` : "Select period"}
                        <TiArrowSortedDown/>
                    </div>
                    {!!paymentPeriod && (
                        <div className="payment-period-until">Until {getDateFormat(startDate, true, true)}</div>
                    )}
                    {showPeriods && (
                        <div className="upload-dropdown-list">
                            <ul className="payment-option-list">
                                {periodsList.map((p) => {
                                    return (
                                        <li
                                            key={p}
                                            // TODO always 3 days for now
                                            onClick={() => handleSelectPeriod(p)}
                                            className={classNames("payment-option-single", {"active": p === paymentPeriod})}
                                            >
                                            {p} days
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PayPerView