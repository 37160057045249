import "./VideoPlayer.css";
import React, {useState, useMemo, useEffect, useRef} from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import {MdForward10, MdReplay10} from "react-icons/md";
import {BsPlay} from "react-icons/bs";
import {useVideoPlayer} from "./VideoPlayerProvider";

export default function VideoPlayer () {

    const {
        playerRef,
        playbackUrl,
        playlist,
        playing,
        setPlaying,
        muted, setMuted,
        volume, setVolume,
        setIsLoading,
        setVideoProgress,
        autoPlay,
    } = useVideoPlayer();

    const wrapperRef = useRef(null)

    const [videoLoaded, setVideoLoaded] = useState(false)
    const poster = playlist?.hd_thumbnail_override_url || playlist?.hd_thumbnail_url || null
    const isPlaceholder = playlist?.is_placeholder
    const isLive = playlist?.is_live || playlist?.game?.has_live_playlist
    let videoUrl = isLive ? playlist?.video_url?.replace("/live/", "/live/event/") : playbackUrl
    if (!playlist) videoUrl = playbackUrl

    useEffect (() => {
        if (isLive) {
            const liveVideoProgress = ((playerRef.current?.currentTime() / (playlist?.duration_ms/1000)) * 100).toFixed(3)
            setVideoProgress(liveVideoProgress)
        }
    }, [playlist, isLive])
    

    const options = useMemo(() => ({
        autoplay: autoPlay,
        controls: true,
        responsive: true,
        liveui: true,
        fill: true,
        poster: autoPlay ? null : poster,
        preload: "none",
        inactivityTimeout: 0,
        source: {
            src: videoUrl,
            type: "application/x-mpegurl",
        },
        controlBar: {
            playToggle: {
                replay: false
            }
        }
    }), [videoUrl, autoPlay])

    useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement("video-js")

            wrapperRef.current.prepend(videoElement)

            const player = playerRef.current = videojs(videoElement, options, () => {
                videojs.log("player is ready", player)
                player.src(options.source)
                videojs.log.level("debug")
            })
            player.on("volumechange", () => {
                setMuted(player.muted())
                setVolume(player.volume())
            })
            player.on("waiting", () => {
                videojs.log("player is waiting")
            })
            player.on("dispose", () => {
                videojs.log("player will dispose")
            })
            player.on("error", e => {
                console.log(
                    "error:",
                    player.error().MEDIA_ERR_SRC_NOT_SUPPORTED,
                    player.error().code,
                    player.error().message)
            })
            player.on("timeupdate", () => {
                if (!isLive) setVideoProgress(((player.currentTime() / player.duration()) * 100).toFixed(3));
            })
            player.on("loadedmetadata", () => {
                setIsLoading(false);
            })
            player.on("loadeddata", () => {
                setVideoLoaded(true);
            })
            player.on("play", () => {
                setPlaying(true)
            })
            player.on("pause", () => {
                setPlaying(false)
            })
            player.muted(muted)
            player.volume(volume)

            const Button = videojs.getComponent("Button")
            const restart = new Button(player, {
                clickHandler: () => playerRef.current?.currentTime(0),
                controlText: "Play from beginning"
            })
            player.getChild("ControlBar").addChild(restart, {}, 1)
            const buttonDom = restart.el()
            buttonDom.innerHTML = `
                <button class="vjs-play-control vjs-control vjs-button vjs-ended" type="button" aria-disabled="false">
                    <span class="vjs-icon-placeholder" aria-hidden="true"></span>
                </button>
            `
        } else {
            const player = playerRef.current
            player.poster(options.poster)
            player.preload(options.preload)
            player.autoplay(options.autoplay)
            player.src(options.source)
        }
    }, [options, wrapperRef])

    window.videojs = videojs // make the videojs library visible globally, for debugging in dev console
    window.showVideoLogs = () => { console.log(videojs.log.history()) }

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose()
                playerRef.current = null
            }
        }
    }, [playerRef])

    useEffect(() => {
        if (playing) onPlay()
        if (!playing) onPause()
    }, [playing])

    function onPlay () {
        playerRef.current?.play()
        setPlaying(true)
    }

    function onPause () {
        playerRef.current?.pause()
        setPlaying(false)
    }

    function onFastForward () {
        if (!videoLoaded) onPlay()
        const currentTime = playerRef.current?.currentTime()
        playerRef.current?.currentTime(currentTime + 10)
    }

    function onRewind () {
        const currentTime = playerRef.current?.currentTime()
        if (currentTime === 0) return
        playerRef.current?.currentTime(currentTime - 10)
    }

    return (
        <>
            <div ref={wrapperRef} className="video-wrap">
                {!playing && (
                    <div className="video-player-play-btn" onClick={onPlay}>
                        <BsPlay/>
                    </div>
                )}
            </div>
            {!isPlaceholder && (
                <div className="video-controls">
                    <div className="video-controls-btn">
                        <MdReplay10 className="icon-button" onClick={onRewind}/>
                        <MdForward10 className="icon-button" onClick={onFastForward}/>
                    </div>
                </div>
            )}
        </>
    )
}