import React, { useState, useRef } from "react"
import { useBackend } from "../../utility/Backend";
import classNames from "classnames"
import { ClickOutside } from "../../utility/Utilities";
import "./Payment.css"
import {TiArrowSortedDown} from "react-icons/ti";

function Subscription ({ 
    merchant,
    subscription,
    setSubscription,
    disabled,
}) {

    const [showSubscriptions, setShowSubscriptions] = useState(false)

    const subscriptionListRef = useRef(null)
    const dropdownListRef = useRef(null)
    ClickOutside(subscriptionListRef, setShowSubscriptions)
    ClickOutside(dropdownListRef, setShowSubscriptions)

    const {data: subscriptionsData} = useBackend(merchant?.id ? "/cee_merchant/" + merchant.id + "/subscription" : null, {});
    
    if (!merchant) return (
        <div className="select-merchant-for-price">
            Select merchant to show subscriptions
        </div>
    )

    if (merchant && !subscriptionsData) {
        return (
            <div className="select-merchant-for-price">
                Getting subscription...
            </div>
        )
    }
    
    const subscriptionsOptions = subscriptionsData?.subscriptions || []
    const subscriptionPrice = subscription?.price_plans[0]

    const handleShowSubscriptions = () => {
        if (disabled) return
        else setShowSubscriptions(!showSubscriptions)
    }

    const handleSelectSubscription = (subscription) => {
        setSubscription(subscription)
        setShowSubscriptions(false)
    }

    if (subscriptionsOptions?.length === 0) {
        return (
            <div className="select-merchant-for-price">
                No available subscription
            </div>
        )
    }

    return (
        <div ref={subscriptionListRef} className="input-container payment-dropdown">
            <label className="input-title">Subscription</label>
            <div ref={dropdownListRef} className="upload-dropdown-cont">
                <div onClick={handleShowSubscriptions} className="upload-dropdown-title">
                    {subscription? 
                        `${subscription.name} - ${subscriptionPrice.price_amount} ${subscriptionPrice.price_currency}`
                    : "Select subscription"}
                    <TiArrowSortedDown/>
                </div>
                {showSubscriptions && (
                    <div className="upload-dropdown-list">
                        <ul className="payment-option-list">
                            {subscriptionsOptions.map((s) => {
                                const price = s.price_plans[0]
                                return (
                                    <li
                                        key={s.id}
                                        onClick={() => handleSelectSubscription(s)}
                                        className={classNames("payment-option-single", {"active": s.id === subscription?.id})}
                                        >
                                        {s.name} - {price.price_amount} {price.price_currency}
                                    </li>
                                )
                            })}
                        </ul> 
                    </div>        
                )}
            </div>
        </div>
    )
}

export default Subscription