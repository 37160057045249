import React, { useState, useEffect, useRef } from "react";
import Config from "../../utility/Config";
import {useBackend} from "../../utility/Backend";
import {useLoginSession} from "../../stores/loginSession";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import { useUpdateSearchParams } from "../../utility/Utilities";
import Backend from "../../utility/Backend";
import ConfirmModal from "../../components/ConfirmModal";
import "./UserGroupInfo.css"
import classNames from "classnames";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import {FaCheck, FaUserCircle, FaTrashAlt} from "react-icons/fa";
import {TiGroup} from "react-icons/ti";

function UserGroupInfo () {

    const findUserRef = useRef(null)
    const [searchParams,,] = useUpdateSearchParams();
    const {user, token} = useLoginSession()
    const query = {access_token: token}
    const {showFeedback} = useFeedbackMessage()
    const isSef = Config.association === "SEF"
    const userGroupId = searchParams.get("user")

    const [userInput, setUserInput] = useState("")
    const [searchResult, setSearchResult] = useState("")
    const [removeUser, setRemoveUser] = useState(null)
    const [errorMsg, setErrorMsg] = useState("")

    const showErrorMessage = (message="") => {
        setErrorMsg(message);
    }

    useEffect(() => {
        setUserInput("")
        setSearchResult("")
        showErrorMessage()
    }, [userGroupId])

    useEffect(() => {
        if (userInput) showErrorMessage();
        if (userInput === "") setSearchResult("")
    }, [userInput])

    useEffect(() => {
        if (searchResult && searchResult.total === 0) showErrorMessage("No users found. Please enter an already registered email or fotbollkonto id");
    }, [searchResult])

    const {data: userGroupDetails, mutate} = useBackend("/usergroup/" + userGroupId, query)
    if (!userGroupDetails) return null
    
    userGroupDetails.users.sort(function (a, b) {
        const groupA = a.name.toLowerCase()
        const groupB = b.name.toLowerCase()
        return (groupA < groupB)? -1 : (groupA > groupB)? 1:0
    })

    const clearAddUser = () => {
        setSearchResult("")
        setUserInput("")
        showErrorMessage()
    }

    const userFound = searchResult && searchResult.total !== 0
    const searchedUserId = userFound && searchResult.users[0].id

    const assignToGroup = () => {
        Backend.post("/usergroup/" + userGroupId + "/user/" + searchedUserId, {}, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error)
                    showFeedback("warning", "Failed to assign user, " + error)
                } else {
                    mutate(userGroupDetails)
                    showFeedback("success", `${searchResult.users[0].name} has been added to ${userGroupDetails.name} successfully`)
                }
            });
        clearAddUser()
    }

    const removeFromGroup = (id) => {
        Backend.delete("/usergroup/" + userGroupId + "/user/" + id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error)
                    showFeedback("warning", "Failed to remove user, " + error)
                } else {
                    mutate(userGroupDetails)
                }
            });
    }
    
    let getUserQuery = {email: userInput.trim(), access_token: token}
    if (!userInput.includes("@")) getUserQuery = {fotbollkonto_id: userInput.trim(), access_token: token}

    const getUser = () => {
        if (!userInput) {
            showErrorMessage("Please enter an already registered email or fotbollkonto id")
            return
        }
        Backend.get("/user/search", getUserQuery)
            .then(({data, error}) => {
                if (error) {
                    console.error("Failed to GET", error)
                    showErrorMessage("no users found")
                } else {
                    mutate(userGroupDetails)
                    setSearchResult(data)
                }
            });
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            getUser()
        }
    }
    
    const isUserSelf = removeUser? user.name === removeUser.name : false
    const userToBeRemoved = isUserSelf? "yourself" : removeUser?.name
    
    const confirmRemoveUserModal = (
        <ConfirmModal
            isOpen
            onClose={() => setRemoveUser(null)}
            onConfirm={() => removeFromGroup(removeUser.id)}
            cancelText="Cancel"
            confirmText="Remove">
            <div className="confirm-icon-message">
                <div className="confirm-icon"><AiOutlineExclamationCircle/></div>
                <div className="confirm-title">Are you sure you want to remove {userToBeRemoved} from this group?</div>
            </div>
        </ConfirmModal>
    )

    const userGroupsUSersList = userGroupDetails.users.length !==0 ? (
        <div className="group-user-cont">
            <div className="group-user-title">Users</div>
            {userGroupDetails.users.map((user) => {
                return (
                    <div key={user.id} className="group-user-single">
                        <div className="group-user-name">
                            <div>
                                {user.profile_image? (<img src={user.profile_image} alt="logo"/>) : 
                                (<div className="group-user-icon"><FaUserCircle/></div>)}
                            </div>
                            <div>{user.name}</div>
                        </div>
                        <div onClick={() => setRemoveUser(user)} className="group-user-remove">
                            <FaTrashAlt/>
                        </div>
                    </div>
                )
            })}
        </div>
    ) : (
        <div className="group-no-user">No users this group</div>
    )

    const userGroupUsersId = userGroupDetails.users.map(user => user.id)
    const userInUserGroup = userFound && userGroupUsersId.includes(searchedUserId)
    const placeholderInfo = isSef ? "Enter registered email or fotbollkonto id here" : "Enter registered email here"

    const addUserSection = (
        <div className="add-user-cont">
            <div className="data-info-head-title">Add user</div>
            <div className="add-user-search">
                <input 
                    type="text"
                    onKeyDown={handleEnter}
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder={placeholderInfo}
                    />
                <button ref={findUserRef} type="button" onClick={getUser}>Find</button>
            </div>
            <div className="warning-msg">{errorMsg}</div>
            <div className="add-user-result">
                {userFound && (
                    <div className="add-user-result-name">
                        <div className="add-user-profile">
                            {searchResult.users[0].profile_image? (<img src={searchResult.users[0].profile_image} alt="logo"/>) : 
                            <FaUserCircle className="add-user-profile-icon"/>}
                        </div>
                        <div className="add-user-display-name">
                            {searchResult.users[0].username || searchResult.users[0].fotbollkonto_unique_name}
                        </div>
                        {!userInUserGroup && <FaCheck className="add-user-check"/>}
                        {!userInUserGroup && <button onClick={assignToGroup}>Add</button>}
                    </div>
                )}
                <div className={classNames("user-in-list", {"visible": userInUserGroup})}>This user is already in the user group</div>
            </div>
        </div>
    )

    return (
        <div className="user-group-info-cont">
            <div className="user-group-info">
                <div className="user-group-name">
                    <div className="user-group-logo">
                        {userGroupDetails.logo_url? (<img src={userGroupDetails.logo_url} alt="logo"/>) : 
                        (<TiGroup className="user-group-logo-icon"/>)}
                    </div>
                    {userGroupDetails.name}
                </div>
                {userGroupsUSersList}
            </div>
            {addUserSection}
            {removeUser && confirmRemoveUserModal}
        </div>
    )
}

export default UserGroupInfo