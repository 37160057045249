import React, {useState, useEffect} from "react"
import { useBackend } from "../../utility/Backend";
import { LoadingPage } from "../../components/Page";
import { Paging } from "../../utility/Paging";
import { capitalizeFirstLetter, getSizeFormat } from "../../utility/Utilities";
import { useUpdateSearchParams, getDateFormat } from "../../utility/Utilities";
import classNames from "classnames";
import "./ExportRecord.css"
import { MdOutlineArrowRight } from "react-icons/md";

function ExportRecordList () {

    const [searchParams,,] = useUpdateSearchParams()

    const fromParam = searchParams.get("from")
    const toParam = searchParams.get("to")
    const fromDate = new Date(fromParam).toISOString()
    const toDate = new Date(toParam).toISOString()

    const [exportRecordPage, setExportRecordPage] = useState(1)

    useEffect(() => {
        setExportRecordPage(1)
    }, [fromDate, toDate])

    const resultsPerPage = 10
    const fromPage = exportRecordPage === 1 ? 0 : (exportRecordPage-1) * resultsPerPage

    const {data: exportRecordData} = useBackend("/video_export/record", {
        count: resultsPerPage,
        from: fromPage,
        from_date: fromDate,
        to_date: toDate,
    })

    if (!exportRecordData) return <LoadingPage/>
    
    const exportRecord = exportRecordData?.records || []
    const totalExportRecordPage = Math.ceil(exportRecordData?.total / resultsPerPage)

    const onPageChange = (page) => {
        setExportRecordPage(page)
    }

    if (exportRecord.length === 0) return (
        <div className="no-export-record">
            No export record
        </div>
    )

    const exportRecordTable = (
        <div className="export-record-table scrollable">
            <div className="table-header-cont sticky">
                <div className="export-record-date">Exported</div>
                <div className="export-record-user">User</div>
                <div className="export-record-size">Size</div>
                <div className="export-record-format">Format</div>
                <div className="export-record-type">Type</div>
                <div className="export-record-status">Status</div>
            </div>
            <div className="export-record-list">
                {exportRecord.map((e) => {
                    return (
                        <div key={e.id} className="export-record-single">
                            <div className="export-record-date">
                                {getDateFormat(e.created_at, true, true)}
                            </div>
                            <div className="export-record-user">
                                {e.creator.name}
                            </div>
                            <div className={classNames("export-record-size", {"data": !!e.size_bytes})}>
                                {e.size_bytes ? getSizeFormat(e.size_bytes) : "-"}
                            </div>
                            <div className="export-record-format">
                                {e.parameters.format || "-"}
                            </div>
                            <div className="export-record-type">
                                {e.type === "ONLINE" ? "Direct download" : "Prepared export"}
                            </div>
                            <div className={classNames("export-record-status", {
                                "success": e.status === "SUCCESS",
                                "failed": e.status === "FAILED",
                                })}>
                                {e.status ? capitalizeFirstLetter(e.status.toLowerCase()) : "-"}
                                {/* {capitalizeFirstLetter(e.status.toLowerCase())} */}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

    return (
        <div className="export-record">
            {exportRecordTable}
            <Paging page={exportRecordPage} pageCount={totalExportRecordPage} onChange={onPageChange}/>
        </div>
    )
}

function ExportRecord () {

    const [searchParams,,] = useUpdateSearchParams()

    const fromParam = searchParams.get("from")
    const toParam = searchParams.get("to")
    const fromDate = new Date(fromParam).toISOString()
    const toDate = new Date(toParam).toISOString()
    
    const {data: exportRecordSummaryData, error} = useBackend("/video_export/record/summary", {
        from_date: fromDate,
        to_date: toDate,
    })

    const [showAllExports, setShowAllExports] = useState(false)
    
    if (error) return (
        <div className="no-export-record">
            No export record
        </div>
    )
    
    if (!exportRecordSummaryData) return <LoadingPage/>

    const {
        offline_bytesize, 
        online_bytesize, 
        total_bytesize, 
        total_exports, 
        total_offline_exports, 
        total_online_exports
    } = exportRecordSummaryData

    // If everything is zero, no export record at all
    if (offline_bytesize === 0 && 
        online_bytesize === 0 && 
        total_bytesize === 0 && 
        total_exports === 0 && 
        total_offline_exports === 0 && 
        total_online_exports === 0
    ) {
        return  (
            <div className="no-export-record">
                No export record
            </div>
        )
    }

    const exportSummary = (
        <div className="export-record-table export-summary">
            <div className="table-header-cont">
                <div className="export-summary-type">Type</div>
                <div className="export-summary-count">Count</div>
                <div className="export-summary-size">Size</div>
            </div>
            <div className="export-summary-data">
                <div className="export-summary-type">Direct download</div>
                <div className="export-summary-count">{total_online_exports}</div>
                <div className="export-summary-size">{getSizeFormat(online_bytesize)}</div>
            </div>
            <div className="export-summary-data">
                <div className="export-summary-type">Offline export</div>
                <div className="export-summary-count">{total_offline_exports}</div>
                <div className="export-summary-size">{getSizeFormat(offline_bytesize)}</div>
            </div>
            <div className="export-summary-data total">
                <div className="export-summary-type">Total</div>
                <div className="export-summary-count">{total_exports}</div>
                <div className="export-summary-size">{getSizeFormat(total_bytesize)}</div>
            </div>
        </div>
            
    )

    const showAllExportsBtn = (
        <div 
            onClick={() => setShowAllExports(!showAllExports)} 
            className={classNames("show-all-exports", {"show": showAllExports})}
            >
            Show all exports
            <MdOutlineArrowRight />
        </div>
    )

    return (
        <div className="export-record-cont">
            {exportSummary}
            {showAllExportsBtn}
            {showAllExports && <ExportRecordList/>}
        </div>
    )
}

export default ExportRecord