import React from "react"
import "./PercentageProgress.css"
import classNames from "classnames"

function PercentageProgress ({percentage, title, type=null}) {
    
    const percentageNumber = percentage || 0
    const progressDegree = 3.6 * percentageNumber

    let progressBar = {
        background: "conic-gradient(var(--light-blue) 90deg,transparent 0deg)",
        animation: "rotate 2s linear infinite",
    }

    if (percentage) progressBar = {
        background: `conic-gradient(var(--light-blue) ${progressDegree}deg,transparent 0deg)`
    }

    return (
        <div className={classNames("percentage-progress-main", {"upload": type === "upload"})}>
            <div className="percentage-progress">
                <div className="percentage-progress-bar" style={progressBar}></div>
                <div className="percentage-progress-percentage">{percentageNumber}%</div>
            </div>
            <div className="percentage-info-cont">
                {percentage !== null ? (
                    <div className="percentage-progress-status">
                        {title}
                        <div className="percentage-progress-title-dot">.</div>
                        <div className="percentage-progress-title-dot">.</div>
                        <div className="percentage-progress-title-dot">.</div>
                    </div>
                ) : (
                    <div className="percentage-progress-status">
                        Processing...
                    </div>
                )}
                <div className="percentage-processing-time-info">This may take a few minutes</div>
            </div>
        </div>
    )
}

export default PercentageProgress