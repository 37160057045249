import React, {useEffect, useState, useRef, useMemo} from "react"
import { useBackend } from "../../utility/Backend"
import Backend from "../../utility/Backend"
import ClubConfigColors from "./ClubConfigColors"
import ClubConfigHero from "./ClubConfigHero"
import { ClubConfigTeamOptions, ClubConfigControls, ClubConfigSelection } from "./ClubConfigUtils"
import { useUpdateSearchParams } from "../../utility/Utilities"
import { useCheckIsAdmin } from "../../utility/UserGroups"
import Page, { LoadingPage } from "../../components/Page"
import { Helmet } from "react-helmet-async"
import { useGetUsersGroups } from "../../utility/UserGroups"
import { useFeedbackMessage } from "../../stores/FeedbackMessage"
import { checkColorContrast, computeColorCssVariables } from "./clubColorUtils"
import Config from "../../utility/Config"
import { RedirectHome } from "../../App"
import { useNavigationBlock } from "../Layout/CustomRouter"
import "./ClubConfig.css"

function ClubConfigSection ({clubConfig, editMode, setEditMode, configSection, mutate}) {

    const {colors, css, hero_image: heroImage} = clubConfig

    const [edits, setEdits] = useState([])
    const [previewData, setPreviewData] = useState(null)
    const [cssVariables, setCssVariables] = useState(css)
    const [previewWindowIsOpen, setPreviewWindowIsOpen] = useState(false)

    const [searchParams,,] = useUpdateSearchParams()    
    const teamParam = searchParams.get("team")
    const {showFeedback} = useFeedbackMessage()
    
    // all edits that are not "reset" or set to original
    const activeEdits = edits.filter((e) => !e.reset)
    
    const resetEdit = () => {
        setEdits([])
        setPreviewData(null)
        setCssVariables(css)
        setPreviewWindowIsOpen(false)
        setEditMode(false)    
    }
    
    useEffect(() => {
        resetEdit()
    }, [teamParam])

    useEffect(() => {
        if (!editMode) resetEdit() 
    }, [editMode])

    const previewWindowRef = useRef(null)

    const openPreviewWindow = (data) => {
        previewWindowRef.current = window.open(
            `${Config.webHref}${data.route}?config_preview_id=${data.preview_id}`, 
            "childWindow", 
            "width=800,height=600,resizable=yes,scrollbars=yes"
        )
    }

    // Periodically check if the preview window is open/closed
    useEffect(() => {
        if (!previewData || !previewWindowRef) return
        
        const checkPreviewWindowStatus = setInterval(() => {
            if (previewWindowRef.current.closed) {
                setPreviewWindowIsOpen(false)
            }
            if (!previewWindowRef.current.closed) {
                setPreviewWindowIsOpen(true)
            }
        }, 2000)

        return () => {
            clearInterval(checkPreviewWindowStatus);
            if (previewWindowRef.current && !previewWindowRef.current.closed) {
                previewWindowRef.current.close();
            }
        }
    }, [])
    
    function updateEdits (newEdit) {

        const updatedEdits = structuredClone(edits)
        // Find the index of the object with the same name
        const index = updatedEdits.findIndex(edit => edit.name === newEdit.name);
        
        // If the name is already in the object, overwrite the value 
        if (index !== -1) updatedEdits[index] = newEdit
        // Else, add new name and value
        else updatedEdits.push(newEdit);

        setEdits(updatedEdits)
    }
    
    const computeConfigObject = (configType, active=false, css=false) => {

        let config
        
        if (configType === "heroImage") config = heroImage
        if (configType === "color") config = colors
        if (configType === "css") config = cssVariables

        let updatedConfigObject

        const editsConfig = active ? activeEdits : edits
        const configOverrides = editsConfig.filter((e) => {return e.type === configType})
        
        // If there's overrides, overwrite it/them to the config 
        if (configOverrides.length !== 0) {
            const configOverridesObject = configOverrides.reduce((acc, value) => (
                { ...acc, [value.name]: value.value }
            ), {})
            const updatedObject = {...config, ...configOverridesObject}
            updatedConfigObject = updatedObject
        } 
        else if (css) {
            updatedConfigObject = config
        }
        return updatedConfigObject
    }
    
    // Objects that need to be submitted when updating preview (active edits and resets)
    const heroImageObject = computeConfigObject("heroImage")
    const colorsObject = computeConfigObject("color")
    const cssObject = computeConfigObject("css", true, true)
    
    // Objects that will be submitted when submitting config changes - only active edits (no resets)
    const heroImageObjectActive = computeConfigObject("heroImage", true)
    const colorsObjectActive = computeConfigObject("color", true)
    const includeCss = !!colorsObjectActive || !!computeConfigObject("css", true)

    // Update css variables (color overrides) when colors are changed/updated
    useEffect(() => {
        if (edits.length === 0) return
        if (colorsObject) {
            const computed = computeColorCssVariables(colorsObject)
            setCssVariables(computed)
        }  
    }, [edits])

    // Invoking updatePreviewConfig whenever there's change in heroImageObject / colorsObject / cssObject
    useEffect(() => {
        if (!editMode || !previewData) return 

        // Send messages to the existing child window. 
        const sendMessageToChildWindow = () => {
            const object = {source: "forzify-mam", command: "fetch", object: edits}
            previewWindowRef.current.postMessage(object, Config.webHref)
            console.log("sending message to " + Config.webHref)
        }

        const updatePreview = setTimeout( async () => {
            if (heroImageObject || colorsObject || cssObject) {
                await updatePreviewConfig()
                if (previewWindowRef.current) {
                    sendMessageToChildWindow()
                } 
            }
        }, 500)

        return () => {
            clearTimeout(updatePreview)
            clearTimeout(sendMessageToChildWindow)
        }

    }, [edits, heroImageObject, colorsObject, cssObject, previewWindowIsOpen])

    const contrastSummary = useMemo(
        () => checkColorContrast(colorsObject || colors), 
        [edits, editMode, teamParam]
    )
    
    const generatePreviewConfig = async (previewWindow=false) => {

        const configForPreview = {}

        console.log("POST", configForPreview)

        await Backend.post(`/fotbollplay/team/${clubConfig.team.id}/clubconfig/preview`, JSON.stringify(configForPreview))
            .then(({data, error}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    showFeedback("warning", "Failed to create preview" + error);
                    return
                } else {
                    setPreviewData(data)
                    if (previewWindow) {
                        openPreviewWindow(data)
                        showFeedback("success", "Configuration preview in new window");
                    } else {
                        navigator.clipboard.writeText(`${Config.webHref}${data.route}?config_preview_id=${data.preview_id}`)
                        showFeedback("success", "Preview link copied to clipboard")
                    }
                }
            })    
    }

    const updateHeroImage = async (imageFile, type) => {

        let imageName
        if (type === "hero") imageName = "url"
        if (type === "hero_logo") imageName = "logo_url"

        let body = new FormData();
        body.append("image", imageFile)
        const image = body;
        await Backend.post(`/userimage?type=${type}`, image, {}, {json:false})
            .then(({data, error}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    showFeedback("warning", "Failed change hero image, " + error);
                } else {
                    updateEdits({type: "heroImage", name: imageName, value: data.path})
                    console.log("Hero image updated")
                }
            })
    }
    
    const updatePreviewConfig = async (payload=null) => {

        let configForPreview = {}

        // if there's payload, the payload will be used when updating the preview (use case: reset all colors)
        if (payload) {
            configForPreview = payload
        } 
        else {
            if (heroImageObject) configForPreview.hero_image = heroImageObject
            if (colorsObject) configForPreview.colors = colorsObject
            if (cssObject) configForPreview.css = cssObject
        }
        
        console.log("PUT preview", configForPreview)
        
        await Backend.put(`/fotbollplay/clubconfig/preview/${previewData.preview_id}`, JSON.stringify(configForPreview))
            .then(({error}) => {
                if (error) {
                    console.error("Failed to PUT", error);
                    showFeedback("warning", "Failed to update preview" + error);
                } else {
                    
                }
            })
    }

    const submitConfig = async () => {

        const configForSubmit = {}

        if (colorsObjectActive) configForSubmit.colors = colorsObjectActive
        if (heroImageObjectActive) configForSubmit.hero_image = heroImageObjectActive
        if (includeCss) configForSubmit.css = cssObject
        
        console.log("POST", configForSubmit)

        await Backend.put(`/fotbollplay/team/${clubConfig.team.id}/clubconfig`, JSON.stringify(configForSubmit))
            .then(async ({error}) => {
                if (error) {
                    console.error("Failed to PUT", error);
                    showFeedback("warning", "Failed to submit club config" + error);
                } else {
                    await mutate()
                    resetEdit()
                    showFeedback("success", "Club config submitted");
                }
            })
    }

    return (
        <div className="club-config-cont">
            <ClubConfigControls
                    editMode={editMode} 
                    setEditMode={setEditMode} 
                    generatePreviewConfig={generatePreviewConfig} 
                    openPreviewWindow={openPreviewWindow}
                    submitConfig={submitConfig} 
                    previewData={previewData}
                    previewWindowIsOpen={previewWindowIsOpen}
                    resetEdit={resetEdit}
                    />
            <div className="club-config-section-cont">
                <ClubConfigHero 
                    heroImage={heroImage} 
                    edits={activeEdits} 
                    updateEdits={updateEdits} 
                    editMode={editMode} 
                    updateHeroImage={updateHeroImage}
                    configSection={configSection}
                    />
                <ClubConfigColors 
                    colors={colors} 
                    originalCss={css}
                    css={cssVariables} 
                    setCssVariables={setCssVariables}
                    edits={activeEdits} 
                    setEdits={setEdits}
                    updateEdits={updateEdits} 
                    editMode={editMode} 
                    configSection={configSection}
                    contrastSummary={contrastSummary}
                    previewWindowIsOpen={previewWindowIsOpen}
                    updatePreviewConfig={updatePreviewConfig}
                    />
            </div>
        </div>
    )
}

function ClubConfigMain () {
    
    const [,isAdmin] = useCheckIsAdmin()
    const usersUserGroups = useGetUsersGroups()
    const [searchParams, updateSearchParams,] = useUpdateSearchParams()    
    const teamParam = searchParams.get("team")

    const {data, mutate} = useBackend("/fotbollplay/clubconfig?nocache")
    const clubConfigData = data || []
    const allConfigs = Object.values(clubConfigData).flat() || []
    const firstConfigTeamId = isAdmin && allConfigs[0]?.id 

    const [editMode, setEditMode] = useState(false)
    const [configSection, setConfigSection] = useState("starting page")

    // Set team param for admin on first render, because admin can open all clubs' config
    useEffect(() => {
        if (!isAdmin || teamParam) return
        updateSearchParams("team", firstConfigTeamId)
    }, [teamParam, firstConfigTeamId])

    useEffect(() => {
        if (configSection !== "starting page") setConfigSection("starting page")
    }, [teamParam])

    useNavigationBlock("The club configuration has unsaved changes. Are you sure you wish to leave?", editMode)
    
    if (!data) return <LoadingPage/>

    // Admin, get config from team param
    const configFromParam = allConfigs.find((c) => c.id === parseInt(teamParam))

    // Non admin, get config from team's user group id
    const teamConfig = () => {
        const userGroupId = usersUserGroups[0]?.id
        const teamConfig = allConfigs.find((c) => c.usergroup_id === userGroupId)
        return teamConfig
    }

    const clubConfig = isAdmin ? configFromParam : teamConfig()
    
    if (!clubConfig) return <LoadingPage/> 

    return (
        <Page centered title="Club config">
            <Helmet>
                <title>Club Config</title>
            </Helmet>
            <div className="club-config-main">
                {isAdmin && (
                    <ClubConfigTeamOptions allConfigs={allConfigs}/>
                )}
                <ClubConfigSelection 
                    clubConfig={clubConfig}
                    configSection={configSection}
                    setConfigSection={setConfigSection}
                    />
                <ClubConfigSection 
                    key={teamParam} 
                    clubConfig={clubConfig} 
                    editMode={editMode} 
                    setEditMode={setEditMode} 
                    configSection={configSection}
                    mutate={mutate}
                    />
            </div>
        </Page>
    )
}

function ClubConfig () {

    const isSef = Config.association === "SEF"

    useEffect(() => {
        if (!isSef) RedirectHome()
    }, [])

    if (!isSef) return null

    return <ClubConfigMain/>
}

export default ClubConfig