import React from "react"
import { useVideoPlayer } from "../../../../components/VideoPlayer/VideoPlayerProvider";
import {formatTimecode} from "../../../../utility/Utilities";
import { IoTriangleSharp } from "react-icons/io5";
import classNames from "classnames";
import "./EditingControls.css";

// TODO Future development: make this component to handle time in milliseconds
function EditingTimelineNumber ({timelineStart, timelineEnd}) {

    const {getCurrentTime} = useVideoPlayer();

    const totalDuration = timelineEnd - timelineStart
    // numbers of lines / seconds
    const linesToShow = Math.floor(totalDuration) + 1

    let lineEverySeconds = 1
    let tallLineEverySeconds = 5
    let timeNumberEverySeconds = 5

    if (linesToShow > 30) {
        timeNumberEverySeconds = 10
    }
    if (linesToShow > 100) {
        lineEverySeconds = 2
        tallLineEverySeconds = 10
        timeNumberEverySeconds = 20
    }
    if (linesToShow > 200) {
        lineEverySeconds = 5
        tallLineEverySeconds = 30
        timeNumberEverySeconds = 30
    }
    if (linesToShow > 400) {
        lineEverySeconds = 5
        tallLineEverySeconds = 30
        timeNumberEverySeconds = 60
    }
    if (linesToShow > 600) {
        lineEverySeconds = 10
        tallLineEverySeconds = 60
        timeNumberEverySeconds = 60
    }
    if (linesToShow > 800) {
        lineEverySeconds = 10
        tallLineEverySeconds = 60
        timeNumberEverySeconds = 120
    }

    const linesToRender = Array.from({length:linesToShow},(_,i)=>i+timelineStart)

    const renderTime = linesToRender.map((m, idx) => {

        const linePosition = ((m - timelineStart) / totalDuration) * 100
        const showLine = m % lineEverySeconds === 0
        const tallTimeLine = m % tallLineEverySeconds === 0
        const showTimeNumber = m % timeNumberEverySeconds === 0

        if (!showLine) return null

        const timeStyles = {
            left: `${linePosition}%`,
            transform: "translateX(-50%)"
        }

        if (idx === 0) timeStyles.transform = "translateX(-10%)"
        if (idx === linesToRender.length - 1) timeStyles.transform = "translateX(-90%)"

        return (
            <div key={m}>
                <div 
                    className={classNames("time-line", {"tall": tallTimeLine || showTimeNumber,})} 
                    style={{left: `${linePosition}%`}}>
                </div>
                {showTimeNumber && (
                    <div className="time-line-number" style={timeStyles}>
                        {formatTimecode(m)}
                    </div>
                )}
            </div>
        )
    })

    const videoProgressPosition = ((getCurrentTime()-timelineStart) / totalDuration) * 100
    let leftPercentage = videoProgressPosition
    if (leftPercentage < 0) leftPercentage = 0
    if (leftPercentage > 100) leftPercentage = 100
    const progressPinPosition = {
        left: `${leftPercentage}%`
    }

    // TODO make a draggable progress pin, only draggable when clicking/dragging the pin
    const progressPin = (
        <div 
            className={classNames("progress-pin", {
            "disabled": videoProgressPosition < 0 || videoProgressPosition > 100,
            })} 
            style={progressPinPosition}
            >
            <div className="progress-pin-arrow"><IoTriangleSharp/></div>
        </div>
    )
    return (
        <div className="timeline-line-cont" >
            {renderTime}
            {progressPin}
        </div>
    )
}

export default EditingTimelineNumber