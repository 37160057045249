import React from "react";
import LoadingIcon from "../../../components/LoadingIcon";
import {useUpdateSearchParams} from "../../../utility/Utilities";
import { getDateFormat } from "../../../utility/Utilities";
import moment from "moment";
import { streamDisplayDuration } from "../StreamDetails/StreamDetails";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import "../Streams.css";
import classNames from "classnames";
import {RiFileCopyLine} from "react-icons/ri";
import {RiFileVideoLine} from "react-icons/ri";
import {IoStop} from "react-icons/io5";
import Config from "../../../utility/Config";

function StreamListData ({stream, stoppingStream}) {

    const {showFeedback} = useFeedbackMessage();

    const [searchParams, updateSearchParams,] = useUpdateSearchParams();

    const streamKeyParams = searchParams.get("stream");

    const showStream = (streamId) => {
        updateSearchParams({"stream": streamId})
    }

    const {playlist, rtmp_stream_key, srt_stream_key} = stream;
    
    let streamKey, streamTypeName

    if (rtmp_stream_key) {
        streamKey = rtmp_stream_key
        streamTypeName = "RTMP"
    }

    if (srt_stream_key) {
        streamKey = srt_stream_key
        streamTypeName = "SRT"
    }

    const copyStreamUrl = (e) => {
        e.stopPropagation();
        if (rtmp_stream_key) navigator.clipboard.writeText(streamKey.rtmp_url);
        if (srt_stream_key) navigator.clipboard.writeText(streamKey.srt_url);
        showFeedback("success", streamTypeName + " server copied to clipboard");
    }

    const copyStreamKey = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(streamKey.stream_key);
        showFeedback("success", streamTypeName + " stream key copied to clipboard");
    }

    const copyRtmpInfo = (
        <div className="copy-stream-info">
            <div className="copy-stream-title">
                <RiFileVideoLine/>
                Copy
            </div>
            <div className="copy-stream-btn-cont">
                <div onClick={copyStreamUrl} className="copy-stream-btn">
                    <RiFileCopyLine /> {streamTypeName} server
                </div>
                <div onClick={copyStreamKey} className="copy-stream-btn">
                    <RiFileCopyLine />  Stream key
                </div>
            </div>
        </div>
    )

    const formattedValidFromHour = moment(new Date(streamKey.valid_from)).format("HH:mm");
    const formattedValidToHour = moment(streamKey.valid_to).format("HH:mm");

    const ingestPeriod = (
        <div className="ingest-period">
            <div className="ingest-period-time">{formattedValidFromHour} to {formattedValidToHour}</div>
            <div className="sd-duration center">
                {streamDisplayDuration(new Date(streamKey.valid_to) - new Date(streamKey.valid_from))}
            </div>
        </div>
    );

    const broadcastStartAndLive = (
        <div className="broadcast-start-and-live">
            <div>{getDateFormat(stream.broadcast_start, true, true)}</div>
            {playlist.is_live && (<div className="live-banner">LIVE</div>)}
        </div>
    )

    const timeAheadBroadcast = new Date(stream.broadcast_start) < new Date()
    const unUsedLiveStream = timeAheadBroadcast && !stream.playlist.is_live
    const streamIsStopping = stoppingStream === stream.id
    
    const isSef = Config.association === "SEF"
    const channelTeamLogo = playlist.channels[0]?.team?.logo_url

    const channelTeamBackgroundLogo = (isSef && channelTeamLogo) && (
        <div className="stream-team-logo-bg">
            <img src={channelTeamLogo} alt="team logo"/>
        </div>
    )
    
    return (
        <div
            className={classNames("stream-data", {
                "info-active": stream.id === streamKeyParams,
                "unused-live": unUsedLiveStream,
                "is-unlisted": stream.playlist.is_private
            })}
            onClick={() => showStream(stream.id)}>
            {channelTeamBackgroundLogo}
            <div className="stream-desc data">
                {playlist.description}
                {stream.playlist.is_private ? (<div className="stream-not-public text-yellow">Not public</div>) : null}
            </div>
            {!streamIsStopping? (
                <>
                    <div className="stream-broadcast data">{broadcastStartAndLive}</div>
                    <div className="stream-valid-to data">{ingestPeriod}</div>
                </>
            ) : (
                <div className="stream-stopping"><LoadingIcon title={"Stopping stream"} icon={<IoStop/>}/></div>
            )}
            <div className="stream-user data">{streamKey.user.name}</div>
            <div className="stream-copy-info">{copyRtmpInfo}</div>
        </div>
    );
}

function StreamList ({streams, stoppingStream}) {

    return (
        <div className="stream-list">
            <div className="stream-list-title-cont">
                <div className="stream-desc">Title</div>
                <div className="stream-broadcast">Broadcast start</div>
                <div className="stream-valid-to">Ingest period</div>
                <div className="stream-user">User</div>
                <div className="stream-copy-info">Stream Info</div>
            </div>
            <div className="stream-list-data">
                {streams.map((s) => (
                    <StreamListData 
                        key={s.id}
                        stream={s}
                        stoppingStream={stoppingStream}/>
                ))}
            </div>
        </div>
    );
}

export default StreamList;