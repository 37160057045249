import React, {useState, useEffect, useRef} from "react"
import Backend from "../utility/Backend";
import { useLoginSession } from "../stores/loginSession";
import { usePeriodFilter } from "../utility/UsePeriodFilter";
import classNames from "classnames";
import { ClickOutside } from "../utility/Utilities";
import FreeTextSearchResults from "./FreeTextSearchResults";
import searchInfo1 from "../img/search_info_1.jpg"
import { FaSearch } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { ImInfo } from "react-icons/im";
import "./Search.css"
import SearchSuggestions from "./SearchSuggestions";

function Search ({isOpen}) {

    const {token} = useLoginSession()
    const query = {access_token: token}
    const searchInputRef = useRef(null)
    const suggestionListRef = useRef(null)
    const searchInfoRef = useRef(null)

    const {from, to, periodFilterOptions} = usePeriodFilter(true, "All time")
    
    const [searchInfoIsOpen, setSearchInfoIsOpen] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [searchProp, setSearchProp] = useState([])
    const [resultSuggestions, setResultSuggestions] = useState([])
    const [suggestionListOpen, setSuggestionListOpen] = useState(false)
    const [inputParams, setInputParams] = useState(null)
    const [activeInput, setActiveInput] = useState(false)
    const [inputCaretPosition, setInputCaretPosition] = useState(null)
    
    const searchFilled = searchInput.split(" ").join("").length >= 3

    const getDataSuggestions = async () => {
        const fromDate = from ? new Date(from).toISOString() : new Date("2020-01-01").toISOString()
        const toDate = to ? new Date(to).toISOString() : new Date().toISOString()
        const {data: allData} = await Backend.get(
            `/freetext/all?p=${searchInput}&from_date=${fromDate}&to_date=${toDate}`,
            {}, query)
        setResultSuggestions(allData)
    }

    ClickOutside(suggestionListRef, setSuggestionListOpen)
    ClickOutside(searchInfoRef, setSearchInfoIsOpen)

    const onKeyPress = (e) => {
        // backspace key
        if (e.keyCode === 8) {
            if (inputCaretPosition === 0 && searchProp.length !== 0) {
                const lastProp = searchProp[searchProp.length-1]
                const updatedList = searchProp.filter((p) => p !== lastProp)
                setSearchProp(updatedList);
            }
        }
        // enter key
        if (e.keyCode === 13) {
            if (searchProp.length !== 0 || searchFilled) {
                submitSearch()
            }
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyPress);
        return () => {
            window.removeEventListener("keydown", onKeyPress);
        }
    })

    useEffect(() => {
        if (activeInput) {
            function caret (e) {
                setInputCaretPosition(this.selectionEnd);
            }
            document.querySelector("input").addEventListener("mouseup", caret);
            document.querySelector("input").addEventListener("keydown", caret);
            return () => {
                document.querySelector("input").removeEventListener("mouseup", caret);
                document.querySelector("input").removeEventListener("keydown", caret);
            }
        }
    }, [activeInput])

    useEffect(() => {
        if (searchFilled) getDataSuggestions()
    }, [searchInput, from, to])

    useEffect(() => {
        searchInputRef.current?.focus()
    }, [isOpen, searchProp])

    useEffect(() => {
        if (!isOpen) {
            resetSearch()
            setInputParams(null)
        }
    }, [isOpen])

    useEffect(() => {
        if (searchFilled) setSuggestionListOpen(true)
        if (!searchFilled && searchProp.length === 0) setSuggestionListOpen(false)
    }, [searchFilled, searchInput, searchProp])

    const resetSearch = () => {
        setSearchInput("")
        setSearchProp([])
        setResultSuggestions([])
    }

    const handleSelectSearchProp = (prop) => {

        const newProp = {tag: prop.tag, name: prop.name}

        if (prop.id) newProp["id"] = prop.id
        
        const propInList = searchProp.find((p) => p.tag === prop.tag)

        if (propInList) {
            const updatedList = searchProp.map((p) => [newProp].find(n => n.tag === p.tag) || p);
            setSearchProp(updatedList)
        } else {
            setSearchProp([...searchProp, newProp])
        }
        setSearchInput("")
    }

    const handleRemoveSearchProp = (tag) => {
        const updatedList = searchProp.filter((p) => p.tag !== tag)
        setSearchProp(updatedList)
    }

    const onFocusSearchInput = () => {
        if (searchFilled && resultSuggestions && !suggestionListOpen) setSuggestionListOpen(true)
        setActiveInput(true)
    }

    const searchPropToParams = () => {

        const params = []

        for (let i = 0; i < searchProp.length; i++) {
            const {tag, name, id} = searchProp[i]
            if (tag === "action") params.push(`a=${name}`)
            if (tag === "player" && id) params.push(`p=${id}`)
            if (tag === "team" && id) params.push(`t=${id}`)
            if (tag === "game" && id) params.push(`g=${id}`)
        }

        if (searchInput) params.push(`d=${searchInput}`)

        let paramsForRequest = ""
        for (let i = 0; i < params.length; i++) {
            paramsForRequest += `${i===0?"?":"&"}${params[i]}`
        }
        // console.log(paramsForRequest);
        return paramsForRequest
    }

    const submitSearch = async () => {
        if (searchProp.length === 0 && !searchFilled) return
        setSuggestionListOpen(false)
        setInputParams(searchPropToParams())
    }

    const searchField = (
        <div className={classNames("search-field", {"active": activeInput})}>
            {searchProp.map((p) => {
                return (
                    <div key={p.tag} className="search-tag">
                        <div className="search-tag-title">{p.tag}:</div>
                        <div className="search-tag-value">{p.name}</div>
                        <LiaTimesSolid onClick={() => handleRemoveSearchProp(p.tag)}/>
                    </div>
                )
            })}
            <input
                id="search-input-end"
                ref={searchInputRef}
                type="text"
                placeholder="Min. 3 characters"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onClick={onFocusSearchInput}
                onFocus={onFocusSearchInput}
                onBlur={() => setActiveInput(false)}
                />
        </div>
    )

    const searchInfo = (
        <div ref={searchInfoRef} className="search-info-cont">
            <div onClick={() => setSearchInfoIsOpen(!searchInfoIsOpen)} className="search-info-btn">
                <ImInfo/>
                Search info
            </div>
            {searchInfoIsOpen && (
                <div className="search-info">
                    Submit tag and keyword to search video. Tags can be found when filling keyword in the input.
                    <div>Available tags are title, action, player, team.</div>
                    <img src={searchInfo1} alt="screenshot" />
                    <IoMdCloseCircleOutline onClick={() => setSearchInfoIsOpen(false)} className="close-search-info"/>
                </div>
            )}
        </div>
    )

    return (
        <div className={classNames("search-cont", {"open": isOpen})}>
            <div className="smaller-period-filter">
                {periodFilterOptions}
            </div>
            <div className="search-field-cont">
                <div ref={suggestionListRef} className="search-field-and-suggestions">
                    {searchField}
                    {suggestionListOpen && (
                        <SearchSuggestions 
                            resultSuggestions={resultSuggestions}
                            handleSelectSearchProp={handleSelectSearchProp}
                            submitSearch={submitSearch}
                            searchInput={searchInput}
                            searchProp={searchProp}
                            setSearchProp={setSearchProp}
                            suggestionListOpen={suggestionListOpen}
                            setSuggestionListOpen={setSuggestionListOpen}
                            />
                    )}
                </div>
                <div onClick={submitSearch} className="search-button">
                    <FaSearch/>
                    Search
                </div>
            </div>
            {searchInfo}
            {inputParams && (
                <FreeTextSearchResults
                    searchProp={searchProp}
                    searchInput={searchInput}
                    inputParams={inputParams}
                    from={from}
                    to={to}
                    showingSuggestionsOrInfo={suggestionListOpen || searchInfoIsOpen}
                    />
            )}
        </div>
    )
}

export default Search