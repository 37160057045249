import React from "react";
import {useSWRConfig} from "swr";
import {useNavigate} from "react-router-dom";
import VideoSearchEntry from "./VideoSearchEntry";

export default function PlaylistEntry ({
    playlist,
    tabIndex,
    active,
    disableAction,
    selectedVideos,
    addVideoToList,
    removeVideoFromList,
}) {
    const {
        date,
        game,
    } = playlist;

    const {cache} = useSWRConfig();
    const navigate = useNavigate();

    const urlPathname = window.location.pathname
    const urlAfterLibrary = urlPathname.substring(urlPathname.indexOf("y") + 1);

    function onClick () {
        cache.set("editing_playlist", playlist);
        // Doing this a little more manually to avoid using a hook in dozens/hundreds of small components
        const q = new URLSearchParams(window.location.search);
        q.set("editing", "playlist_" + playlist.id);
        navigate("/library" + urlAfterLibrary + "?" + q.toString());
        return false;
    }

    return (
        <VideoSearchEntry
            tabIndex={tabIndex}
            onClick={onClick}
            playlist={playlist}
            game={game}
            timestamp={date}
            active={active}
            disableAction={disableAction}
            selectedVideos={selectedVideos}
            addVideoToList={addVideoToList}
            removeVideoFromList={removeVideoFromList}/>
    );
}
