import React, {useRef, useState, useEffect} from "react";
import Backend from "../../../../utility/Backend";
import "./CompilationEditingSection.css";
import Config from "../../../../utility/Config";
import ConfirmModal from "../../../../components/ConfirmModal";
import {useCompilation} from "../../../../stores/compilation";
import {useFeedbackMessage} from "../../../../stores/FeedbackMessage";
import {VideoPlayerProvider} from "../../../../components/VideoPlayer/VideoPlayerProvider";
import VideoPlayer from "../../../../components/VideoPlayer/VideoPlayer";
import CompilationDragAndDrop from "./CompilationDragAndDrop";
import EditClipInterval from "../EditingControls/EditClipInterval";
import {getDisplayDuration, createPlaybackUrl} from "../../../../utility/Utilities";
import {FaTrashAlt} from "react-icons/fa";
import LoadingScreen from "../../../../components/LoadingScreen";

function getPreviewPlaybackUrl (clips) {
    const {duration, queries} = clips.reduce((acc, clip) => {
        const fromTs = clip.state.from_timestamp.toFixed(0);
        const toTs = clip.state.to_timestamp.toFixed(0);
        acc.queries.push(`${clip.video_asset_id}:${fromTs}:${toTs}`);
        acc.duration += (toTs - fromTs) / 1000;
        return acc;
    }, {duration: 0, queries: []});
    const backend = Config.getBackend();
    const url = `${backend}/playlist.m3u8/${queries.join("_")}/Manifest.m3u8`;
    return [url, duration];
}

export default function CompilationEditingSection ({onSave, onDiscard}) {

    const {
        editIndex,
        clips,
        isPreviewMode,
        clearCompilation,
        saveCompilation,
    } = useCompilation();

    const {showFeedback} = useFeedbackMessage();
    const saveBtnRef = useRef();

    const [isLoading, setIsLoading] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState()
    
    const clip = clips[editIndex] || []
    const assetId = clip.video_asset_id

    useEffect(() => {
        const updateClip = async () => {
            if (clip.state.assetChecked) return

            // if the clip hasn't had the asset checked yet
            setIsLoading(true)
            try {
                const {data: asset} = await Backend.get(`/asset/${assetId}`)
                
                const {duration, url} = createPlaybackUrl(clip, null, Config.paddingDuringEditing, 0, asset?.duration_ms);
                const originalDuration = (clip.to_timestamp - clip.from_timestamp) / 1000
                const endOfVideo = clip.to_timestamp / 1000
                const start = Config.paddingDuringEditing > (clip.from_timestamp/1000) ? clip.from_timestamp/1000 : Config.paddingDuringEditing
                const end = Math.min(start + originalDuration, start + endOfVideo)

                clip.editingDuration = duration
                clip.editingPlaybackUrl = url
                clip.state.start = start
                clip.state.end = end
                clip.state.assetChecked = true
            } catch (error) {
                console.error("Error updating clip:", error);
            } finally {
                setIsLoading(false)
            }
        }
        updateClip()
    }, [assetId])

    if (!clips || clips.length === 0) return (
        <div className="compilation-loading-screen">
            <LoadingScreen/>
        </div>
    ) 
    
    let {editingPlaybackUrl, editingDuration} = clip
    
    if (isPreviewMode) {
        [editingPlaybackUrl, editingDuration] = getPreviewPlaybackUrl(clips);
    }

    function onClipChange (changes) {
        Object.assign(clip.state, changes); 
    }

    async function onClickSave () {
        // Prevent double-clicking by disabling the button
        if (saveBtnRef.current) saveBtnRef.current.setAttribute("disabled", "disabled");
        const {error} = await saveCompilation();
        if (saveBtnRef.current) saveBtnRef.current.removeAttribute("disabled");

        if (error) {
            showFeedback("warning", error);
            return;
        }

        onSave();
        clearCompilation();
        showFeedback("success", "Compilation saved successfully");
    }

    function onClickDiscard () {

        function onConfirm () {
            setConfirmationModal(null);
            clearCompilation();
            onDiscard();
        }

        setConfirmationModal(
            <ConfirmModal
                isOpen
                onClose={() => setConfirmationModal(null)}
                onConfirm={onConfirm}
                cancelText="Continue editing"
                confirmText="Discard">
                <div className="confirm-icon-message">
                    <div className="confirm-icon"><FaTrashAlt/></div>
                    <div className="confirm-title">Are you sure you wish to discard any changes?</div>
                </div>
            </ConfirmModal>
        );
    }

    const oriEvent = clips[editIndex];
    const oriDescription = oriEvent && oriEvent.description
    const oriDuration = oriEvent && (oriEvent.to_timestamp - oriEvent.from_timestamp);
    const oriEventDuration = getDisplayDuration(oriDuration);

    const loadingIndicator = (
        <div className="percentage-progress-status center">
            Loading
            <div className="percentage-progress-title-dot">.</div>
            <div className="percentage-progress-title-dot">.</div>
            <div className="percentage-progress-title-dot">.</div>
        </div>
    )

    return (
        <>
            <VideoPlayerProvider url={editingPlaybackUrl} duration={editingDuration} autoPlay>
                <div className="player-list-cont">
                    <div className="player">
                        {isLoading ? loadingIndicator : <VideoPlayer/>}
                    </div>
                    <div className="clip-list">
                        <CompilationDragAndDrop/>
                    </div>
                </div>
                {!isPreviewMode && (
                    <div className="video-editing-controls">
                        <div className="ori-event-info">
                            <div>{oriEventDuration}</div>
                            <div>{oriDescription}</div>
                        </div>
                        <div className="editing-container">
                            {isLoading ? 
                                loadingIndicator : 
                                <EditClipInterval
                                key={clip.uniqueId}
                                clip={clip}
                                onChange={onClipChange}
                                isCompilation
                                />
                            }
                        </div>
                    </div>
                )}
            </VideoPlayerProvider>

            <div className="confirm-cancel-btn-cont between">
                <button
                    ref={saveBtnRef}
                    type="button"
                    onClick={onClickSave}
                    className="green-btn">
                    Save
                </button>
                <button
                    ref={saveBtnRef}
                    type="button"
                    onClick={onClickDiscard}
                    className="red-btn">
                    Discard changes
                </button>
            </div>

            {confirmationModal && confirmationModal}
        </>
    );
}