import React from "react";
import "./Library.css";
import Page, {DualPage} from "../../components/Page";
import NavLink from "../../components/NavLink";
import {Outlet, useLocation, useParams, useSearchParams} from "react-router-dom";
import EditEvent from "./EditPlaylist/EditEvent";
import {FaVideoSlash} from "react-icons/fa";
import EditPlaylist from "./EditPlaylist/EditPlaylist";
import {useSWRConfig} from "swr";
import {useBackend} from "../../utility/Backend";
import {Helmet} from "react-helmet-async";
import UploadVideo from "../UploadVideo/UploadVideo";
import "../../components/Search.css";
import { FaSearch } from "react-icons/fa";

const EmptyEditPage = () => (
    <Page title="Editing">
        <div className="flex-vertically-centered is-loading">
            <div>
                <FaVideoSlash className="no-video-icon"/>
                <br />
                None selected
            </div>
        </div>
    </Page>
);

function EditingPage () {
    const [searchParams] = useSearchParams();
    const editing = searchParams.get("editing");
    if (!editing) {
        return <EmptyEditPage />
    }

    const [type, id] = editing.split("_");
    
    if (!id) return <EmptyEditPage />
    if (type === "upload") return <UploadVideo />
    if (type === "event") return <EditEvent id={id} />
    if (type === "playlist") return <EditPlaylist id={id} />

    return <EmptyEditPage />
}

const stickyQueryParams = ["editing", "season"];

function getPageTitle (pathname) {
    const path = pathname.replace("/library/", "");
    switch (path) {
        case "matches": return "Matches";
        case "events": return "Official events";
        case "videos": return "Club content";
        default: return "Library";
    }
}

export default function Library () {

    const {pathname} = useLocation();
    const {gameId} = useParams();
    const {cache} = useSWRConfig(); // We use the cache to instantly render when clicking on a search entry

    // NOTE: It would be a little cleaner if page title was defined by the subpage itself.
    // However, because we have 2 "pages", left & right panels, it won't know which tree node to use as cleanly
    let pageTitle = getPageTitle(pathname); // NOTE: If gameId is set, it will override below

    let matchPath = null;
    let fallback = undefined;
    if (gameId) {
        matchPath = "/game/" + gameId;
        fallback = cache.get("viewed_match");
        if (fallback && fallback.id !== gameId) fallback = undefined;
    }

    const {data} = useBackend(
        matchPath,
        undefined,
        {fallbackData: fallback});

    // let activeGamePageLink = null;
    if (data) {
        const home = data.home_team;
        const away = data.visiting_team;
        const gameTitle = `${data.date}: ${home.short_name || home.name} - ${away.short_name || away.name}`
        pageTitle = gameTitle;
        // TODO temporarily hide this because this link doesn't do anything and hanging
        // activeGamePageLink = (
        //     <NavLink to={"/library/match/" + gameId} small withQuery={stickyQueryParams}>{gameTitle}</NavLink>
        // );
    }

    const isMatchPage = pathname.includes("match")
    
    const title = (
        <ul className="navigation-tabs">
            <NavLink to={"/library/matches"} small withQuery={stickyQueryParams} forceActive={isMatchPage}>Matches</NavLink>
            <NavLink to={"/library/events"} small withQuery={stickyQueryParams}>Official events</NavLink>
            <NavLink to={"/library/videos"} small withQuery={stickyQueryParams}>Club content</NavLink>
            <NavLink to={"/library/search"} small withQuery={stickyQueryParams}>
                <FaSearch className="open-search-icon"/>
                Search
            </NavLink>
        </ul>
    );

    return (
        <DualPage withLayoutSelection rightPageQueryParam="editing">
            <Page title={title}>
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                <Outlet />
            </Page>
            <EditingPage />
        </DualPage>
    );
}