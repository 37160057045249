import React, {useEffect, useState} from "react"
import { useCompilation } from "../stores/compilation";
import { useLoginSession } from "../stores/loginSession";
import Backend from "../utility/Backend";
import { useParams } from "react-router-dom";
import { useFeedbackMessage } from "../stores/FeedbackMessage";
import { useUpdateSearchParams } from "../utility/Utilities";
import ConfirmModal from "./ConfirmModal";
import CustomModal from "./CustomModal";
import classNames from "classnames";
import { showErrorMessage } from "../utility/Utilities";
import {MdListAlt} from "react-icons/md";
import {CgUnavailable} from "react-icons/cg";
import {BsInfoCircle} from "react-icons/bs";
import "./css/GenerateHighlights.css";
import "./PercentageProgress.css"

function GenerateHighlights ({game}) {

    const [highlightsType, setHighlightsType] = useState("headToHead")
    const [warningModal, setWarningModal] = useState(null)
    const [highlightsVideo, setHighlightsVideo] = useState(null)
    const [isPrivate, setIsPrivate] = useState(true)
    const [isGeneratingHighlights, setIsGeneratingHighlights] = useState(false)
    const [highlightsError, setHighlightsError] = useState(null)

    const {showFeedback} = useFeedbackMessage();
    const {initializeCompilation} = useCompilation();
    const [, updateSearchParams] = useUpdateSearchParams();
    const {clips, compilationExists, playlistId: compilationId} = useCompilation();

    const {token} = useLoginSession()
    const query = {access_token: token}
    const {gameId} = useParams()

    const {home_team, visiting_team, date} = game
    const gameFinished = new Date() > new Date(game.start_time) && game.phase === "finished"

    const generateEndpoint = () => {
        if (highlightsType === "headToHead") {
            return `/suggest/game/${gameId}/pregame/headtohead/v1`
        }
        if (highlightsType === "home") {
            return `/suggest/game/${gameId}/pregame/team/${home_team.id}/v1`
        }
        if (highlightsType === "away") {
            return `/suggest/game/${gameId}/pregame/team/${visiting_team.id}/v1`
        }
        if (highlightsType === "postGame") {
            return `/suggest/game/${gameId}/postgame/v1`
        }
        else return ""
    }
    
    // POST request to the endpoint to get the data (clips), and initialize it to compilation
    const generateHighlights = async () => {
        setIsGeneratingHighlights(true)
        setWarningModal(null)

        const {data, error} = await Backend.post(generateEndpoint(), query)
        if (error) {
            setHighlightsError(error)
            showFeedback("error", "Failed to generate highlights" + error)
            setIsGeneratingHighlights(false)
        }
        else {
            await initializeCompilation(data, data.id, true);
            setHighlightsVideo(data)
        }
        resetGenerateHighlights()
    }
    
    // When highlights data exist and compilation clips created, navigate to compilation editing
    useEffect(() => {
        if (highlightsVideo && clips) {
            updateSearchParams({"editing": "playlist_" + highlightsVideo.id})
            setHighlightsVideo(null)
            setIsGeneratingHighlights(false)
            showFeedback("success", "New compilation created successfully!");
        }
    }, [highlightsVideo, clips])

    useEffect(() => {
        if (highlightsError) setHighlightsError(false)
    }, [highlightsType])

    const toExistingVideoEdit = () => {
        updateSearchParams("editing", "playlist_" + compilationId);
        setWarningModal(null)
    }

    const generateHighlightsConfirmation = () => {
        if (compilationExists) {
            setWarningModal("compilation exists")
            return
        } 
        if (!gameFinished) setHighlightsType("headToHead")
        if (gameFinished) setHighlightsType("postGame")
        setWarningModal("generate highlights")
    }

    const resetGenerateHighlights = () => {
        setHighlightsType("headToHead")
        setWarningModal(null)
        setIsPrivate(true)
        setHighlightsError(null)
    }

    const preGameHighlightsModal = (
        <CustomModal isOpen onRequestClose={resetGenerateHighlights} className="small-tall">
            <div className="generate-highlights-cont">
                <div className="generate-highlights-title">Generate pre-game highlights</div>
                <div className="generate-highlights-info">
                    <div className="generate-highlights-club">
                        <div>{home_team.short_name}</div>
                        <img src={home_team.logo_url} alt="" />
                    </div>
                    <div className="pre-game-highlights-date">{date}</div>
                    <div className="generate-highlights-club away">
                        <img src={visiting_team.logo_url} alt="" />
                        <div>{visiting_team.short_name}</div>
                    </div>
                </div>
                <div className="generate-highlights-type-cont">
                    <div onClick={() => setHighlightsType("headToHead")} className={classNames("generate-highlights-type", {"active": highlightsType === "headToHead"})}>
                        <div className="generate-highlights-team">Head-to-Head</div>
                        <div className="option-extra-message"><BsInfoCircle className="info-icon"/> Highlights from last 2 head-to-head games</div>
                    </div>
                    <div onClick={() => setHighlightsType("home")} className={classNames("generate-highlights-type", {"active": highlightsType === "home"})}>
                        <div className="generate-highlights-team">
                            <img src={home_team.logo_url} alt="" />
                            <div className="generate-highlights-team-name">{home_team.name}</div>
                        </div>
                        <div className="option-extra-message"><BsInfoCircle className="info-icon"/> Highlights from last 2 games of {home_team.name}</div>
                    </div>
                    <div onClick={() => setHighlightsType("away")} className={classNames("generate-highlights-type", {"active": highlightsType === "away"})}>
                        <div className="generate-highlights-team">
                            <img src={visiting_team.logo_url} alt="" />
                            <div className="generate-highlights-team-name">{visiting_team.name}</div>
                        </div>
                        <div className="option-extra-message"><BsInfoCircle className="info-icon"/> Highlights from last 2 games of {visiting_team.name}</div>
                    </div>
                    {showErrorMessage(highlightsError, highlightsError)}
                    {/* TODO Enable this when needed (public/non-public) */}
                    {/* <div className="generate-highlights-publish">
                        <div className="input-container-toggle">
                            <label className="toggle-title">Publish</label>
                            <div onClick={() => setIsPrivate(!isPrivate)} className={classNames("toggle-switch", {"active": !isPrivate})}>
                                <div className="toggle-button"></div>
                            </div>
                        </div>
                        <div className="option-extra-message"><BsInfoCircle className="info-icon"/> Publish to Highlights page</div>
                    </div> */}
                </div>
                <div className="confirm-cancel-btn-cont full">
                    <button className="green-hover-btn" onClick={generateHighlights}>
                        Generate
                    </button>
                    <button onClick={resetGenerateHighlights}>
                        Cancel
                    </button>
                </div>
            </div>
        </CustomModal>
    )

    const postGameHighlightsModal = (
        <CustomModal isOpen onRequestClose={resetGenerateHighlights} className="small-short">
            <div className="generate-highlights-cont">
                <div className="generate-highlights-title">Generate post-game highlights</div>
                <div className="pre-game-highlights-date">{date}</div>
                <div className="flex-grow">
                    <div className="generate-highlights-info">
                        <div className="generate-highlights-club">
                            <div>{home_team.short_name}</div>
                            <img src={home_team.logo_url} alt="" />
                        </div>
                        <div className="generate-highlights-score">
                            <div>{game.home_team_goals}</div>
                            -
                            <div>{game.visiting_team_goals}</div>
                        </div>
                        <div className="generate-highlights-club away">
                            <img src={visiting_team.logo_url} alt="" />
                            <div>{visiting_team.short_name}</div>
                        </div>
                    </div>
                </div>
                {showErrorMessage(highlightsError, highlightsError)}
                <br />
                <div className="confirm-cancel-btn-cont full">
                    <button className="green-hover-btn" onClick={generateHighlights}>
                        Generate
                    </button>
                    <button onClick={resetGenerateHighlights}>
                        Cancel
                    </button>
                </div>
            </div>
        </CustomModal>
    )

    const generateHighlightsModal = gameFinished ? postGameHighlightsModal : preGameHighlightsModal

    const generatingHighlightsModal = (
        <CustomModal
            isOpen={isGeneratingHighlights}
            onRequestClose={() => setIsGeneratingHighlights(false)}
            className="transparent-modal">
            <div className="percentage-progress-status">
                generating highlights
                <div className="percentage-progress-title-dot">.</div>
                <div className="percentage-progress-title-dot">.</div>
                <div className="percentage-progress-title-dot">.</div>
            </div>
        </CustomModal>
    )

    const compilationExistsModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setWarningModal(null)}
            cancelText="Close"
            noConfirmBtn
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><MdListAlt/></div>
                <div className="confirm-title">You have existing new compilation or highlights. Save or delete the existing to create a new one.</div>
                <span onClick={toExistingVideoEdit} className="to-existing-video-edit">Go to existing compilation / highlights</span>
            </div>
        </ConfirmModal>
    );

    const highlightsNotAvailableModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setWarningModal(null)}
            cancelText="Close"
            noConfirmBtn
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><CgUnavailable/></div>
                <div className="confirm-title">Match is not started or has no events yet</div>
            </div>
        </ConfirmModal>
    );

    return (
        <>
            <button type="button" className="generate-highlights-btn green-hover-btn" onClick={generateHighlightsConfirmation}>
                Generate {gameFinished ? "post-game" : "pre-game"} highlights
            </button>
            {warningModal === "generate highlights" && generateHighlightsModal}
            {warningModal === "compilation exists" && compilationExistsModal}
            {warningModal === "unavailable" && highlightsNotAvailableModal}
            {generatingHighlightsModal}
        </>
    )
}

export default GenerateHighlights