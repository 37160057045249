import {useLoginSession} from "../../../../stores/loginSession";
import { useBackend } from "../../../../utility/Backend";
import {useVideoPlayer} from "../../../../components/VideoPlayer/VideoPlayerProvider";
import React, {useEffect} from "react";
import {createPlaybackUrl} from "../../../../utility/Utilities";
import Config from "../../../../utility/Config";
import Backend from "../../../../utility/Backend";
import EditClipInterval from "./EditClipInterval";
import { useFeedbackMessage } from "../../../../stores/FeedbackMessage";
import { useMutateByRegex } from "../../../../utility/Utilities";

export function EditExistingClip ({
    onSave: onParentSave = null,
    onDiscard: onParentDiscard,
}) {

    const mutateByRegex = useMutateByRegex()
    const {showFeedback} = useFeedbackMessage();
    const {token} = useLoginSession();
    const {playlist, playbackUrl, switchPlayback, resetPlaybackUrl} = useVideoPlayer();
    const clip = playlist.events[0];

    const {data: asset} = useBackend("/asset/" + clip.video_asset_id, {access_token: token})

    useEffect(() => {
        const {duration, url} = createPlaybackUrl(clip, playbackUrl, Config.paddingDuringEditing, 0, asset?.duration_ms);
        console.log("startEditingExistingClip", url);
        switchPlayback(url, duration);
    }, []);

    function onSave (newClip) {

        console.log("Pushing an update to playlist", playlist.id, " -> ", newClip);

        const obj = {events: [newClip]};

        const query = {access_token: token};
        Backend.put("/playlist/" + playlist.id, JSON.stringify(obj), query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to PUT", error);
                    showFeedback("warning", "Failed to edit" + error);
                } else {
                    resetPlaybackUrl();
                    onParentSave();
                    mutateByRegex(/^\/playlist\//)
                    showFeedback("success", "Video edited successfully");
                }
            });
    }

    function onDiscard () {
        resetPlaybackUrl();
        onParentDiscard();
    }

    return (
        <EditClipInterval
            clip={clip}
            onSave={onSave}
            onDiscard={onDiscard}
            withButtons/>
    );
}