import React, {useState, useEffect} from "react"
import Backend from "../../utility/Backend";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { useUpdateSearchParams, showErrorMessage } from "../../utility/Utilities";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";

function CreateNewUser () {

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [hasInputError, setHasInputError] = useState(false)
    const [responseError, setResponseError] = useState(null)

    const [isFsAdmin ,isAdmin] = useCheckIsAdmin()
    const {showFeedback} = useFeedbackMessage()
    const [,,resetAllSearchParamsExcept] = useUpdateSearchParams()
    
    const missingUsername = username.length < 3
    const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const emailIsValid = emailReg.test(email)
    const missingPassword = !password || password.length < 10
    const repeatNewPasswordNotMatch = password && (!password.includes(repeatPassword) || password.length !== repeatPassword.length)
    const disableCreate = missingUsername || !emailIsValid || missingPassword || repeatNewPasswordNotMatch

    useEffect(() => {
        if (!disableCreate) setHasInputError(false)
    }, [disableCreate])

    useEffect(() => {
        if (responseError) setResponseError(null)
    }, [email])

    if (!isFsAdmin) return (
        <div className="create-user-no-permission">
            You don't have permission to access this page
        </div>
    )

    const resetCreateNewUser = () => {
        setUsername("")
        setEmail("")
        setPassword("")
        setRepeatPassword("")
    }

    const onCreateNewUser = () => {

        if (disableCreate) {
            setHasInputError(true)
            return
        }

        const newUserData = {
            username: username,
            email: email,
            password: password
        }

        Backend.post("/user", JSON.stringify(newUserData))
            .then(({error}) => {
                if (error) {
                    console.error("Failed to create new user", error)
                    setResponseError(error)
                } else {
                    console.log("new user created", username);
                    resetCreateNewUser()
                    resetAllSearchParamsExcept([])
                    showFeedback("success", "New user created successfully")
                }
            });
    }

    const createNewUserForm = (
        <div className="create-new-user-form">
            <div className="input-container">
                <label className="input-title">Username</label>
                <input
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}/>
                {hasInputError && showErrorMessage("Username min. 3 characters", missingUsername)}
            </div>
            <div className="input-container">
                <label className="input-title">Email</label>
                <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}/>
                {hasInputError && showErrorMessage("Please fill a valid email", !emailIsValid)}
            </div>
            <div className="input-container">
                <label className="input-title">Password</label>
                <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}/>
                {hasInputError && showErrorMessage("Password min. 10 characters", missingPassword)}
            </div>
            <div className="input-container">
                <label className="input-title">Repeat password</label>
                <input
                    type="password"
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    value={repeatPassword}/>
                {hasInputError && showErrorMessage("Repeat password doesn't match password", repeatNewPasswordNotMatch)}
            </div>
            {showErrorMessage(responseError, responseError)}
        </div>
    )

    return (
        <div className="create-new-user-cont">
            {createNewUserForm}
            <div className="confirm-cancel-btn-cont between">
                <button type="button" onClick={onCreateNewUser} disabled={hasInputError} className="green-btn">Create</button>
                <button type="button" onClick={() => resetAllSearchParamsExcept([])}>Cancel</button>
            </div>
        </div>
    )
}

export default CreateNewUser