import React from "react"
import Config from "../../../utility/Config";
import { getDateFormat } from "../../../utility/Utilities"
import "./PaymentMetadata.css";

function PaymentMetadata ({playlist}) {

    const isSef = Config.association === "SEF"
    const payment = isSef && playlist.payment
    const ppv = isSef && payment?.price_plans[0]
    const subscription = isSef && payment?.subscriptions[0]
    const subscriptionPrice = subscription?.price_plans[0]

    if (!ppv && !subscription) return null

    const startTime = new Date(playlist.recording_timestamp).getTime()
    const paymentRequiredUntil = payment.payment_required_until ? new Date(payment.payment_required_until).getTime() : null
    const paymentPeriod = paymentRequiredUntil ? Math.round((paymentRequiredUntil - startTime) / 86400000) : null
    const displayPaymentPeriod = paymentPeriod ? paymentPeriod + " days" : "-"
    const displayPaymentRequiredUntil = paymentRequiredUntil ? getDateFormat(new Date(payment.payment_required_until)) : "-"
    const merchant = ppv?.merchant || subscription?.merchant
    const teamLogo = merchant?.team?.logo_url

    const premiumPlan = subscription && (
        <div className="payment-plan-single">
            <div className="payment-price-name">{subscription.name} - {subscriptionPrice.price_amount} {subscriptionPrice.price_currency}</div>
            <div className="payment-plan-type">PREMIUM</div>
        </div>
    )

    const ppvPlan = ppv && (
        <div className="payment-plan-single">
            <div className="payment-price-name">{ppv?.name}</div>
            <div className="payment-plan-type">PAY-PER-VIEW</div>
            {(ppv && !payment.required) && (
                <div className="payment-period-ended">Payment period is ended</div>
            )}
            {!subscription && (
                <div className="price-period-cont">
                    <div className="price-period-info">
                        <div className="price-period-title">Required for :</div>
                        <div className="price-period-days">{displayPaymentPeriod}</div>
                    </div>
                    <div className="price-period-info">
                        <div className="price-period-title">Required until :</div>
                        <div className="price-period-until">
                            {displayPaymentRequiredUntil}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )

    return (
        <div className="data-info">
            <div className="data-info-title">Payment</div>
            <div className="payment-plan-cont">
                <div className="price-merchant">
                    {teamLogo && (
                        <img src={teamLogo} alt="logo" className="merchant-club-logo"/>
                    )}
                    <div>{merchant.name}</div>
                </div>
                {premiumPlan}
                {ppvPlan}
            </div>
        </div>
    )
}

export default PaymentMetadata